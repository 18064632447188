import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

// To format the input display date in datepicker
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class CreateAppointmentComponent implements OnInit {
  id!: any;
  appointmentDate!: any;
  todayDate!: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.getSalesPersonDetails();

    this.appointmentDate = new FormControl(new Date());
    this.todayDate = new Date();
    //send page Title to header component
    this.shared.sendAppTitle('Create Appointment');
  }

  getSalesPersonDetails() {
    return localStorage.getItem('salesPerson');
  }

  onSubmit(createAppointmentForm: NgForm) {
    let appointmentData = createAppointmentForm.value;
    if (appointmentData.appointmentTime != '') {
      let timeString = appointmentData.appointmentTime.split(':');
      let appTime = new Date(1970, 0, 1, timeString[0], timeString[1]);
      let appTimeInISO = appTime.toISOString();
      appointmentData.appointmentTime = appTimeInISO;
    }

    //date shall be in UTC format
    try {
      // let convertibleDate = new Date(this.appointmentDate.value.format("YYYY-MM-DD"));
      let convertibleDate = moment(this.appointmentDate.value).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
      appointmentData.appointmentDate = convertibleDate;
    } catch (e) {
      console.error(e);
      return;
    }

    if (
      appointmentData.appointmentDate != '' &&
      appointmentData.appointmentType != ''
    ) {
      // appointmentData.
      appointmentData.id = this.id;

      //sales person details
      let salesPersonDetails: any = this.getSalesPersonDetails();
      salesPersonDetails = JSON.parse(salesPersonDetails);
      appointmentData.userId = salesPersonDetails.userId;
      appointmentData.inchargeUserId = salesPersonDetails.userId;
      appointmentData.inchargeName = salesPersonDetails.name;
      appointmentData.inchargeMobileNumber = salesPersonDetails.mobile;
      appointmentData.leadStatus = 'APPOINTMENT'; //default: converting any Lead to APPOINTMENT while creating appointment
      this.userService
        .createAppointment(appointmentData)
        .subscribe((response) => {
          if (response.status == 'Success') {
            alert('Appointment Created Successfully');
            this.router.navigate(['appointments']);
          } else {
            alert('Update Appointment Failed');
            this.router.navigate(['assigned-leads']);
          }
        });
    }
  }

  onCancel() {
    this.router.navigate(['appointments']);
  }
}
