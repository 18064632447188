import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcquisitionsComponent } from './controllers/acquisitions/acquisitions.component';
import { AddUserComponent } from './controllers/add-user/add-user.component';
import { FollowUpComponent } from './controllers/follow-up/follow-up.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { PricingInfoComponent } from './controllers/pricing-info/pricing-info.component';
import { AssignedLeadsComponent } from './controllers/assigned-leads/assigned-leads.component';
import { AppointmentsComponent } from './controllers/appointments/appointments.component';
import { EditComponent } from './controllers/edit/edit.component';
import { CreateAppointmentComponent } from './controllers/create-appointment/create-appointment.component';
import { EditAppointmentComponent } from './controllers/edit-appointment/edit-appointment.component';
import { DirectionNavigateComponent } from './controllers/direction-navigate/direction-navigate.component';
import { SearchComponent } from './controllers/search/search.component';
import { AgentComponent } from './controllers/agent/agent.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './shared/login/login.component';

const routes: Routes = [
  { path: 'add-user', component: AddUserComponent, canActivate: [AuthGuard] },
  { path: 'follow-up', component: FollowUpComponent, canActivate: [AuthGuard] },
  { path: 'acquisition', component: AcquisitionsComponent, canActivate: [AuthGuard] },
  { path: 'pricing-info', component: PricingInfoComponent, canActivate: [AuthGuard] },
  { path: 'assigned-leads', component: AssignedLeadsComponent, canActivate: [AuthGuard] },
  { path: 'appointments', component: AppointmentsComponent, canActivate: [AuthGuard] },
  { path: 'edit', component: EditComponent, canActivate: [AuthGuard] },
  { path: 'create-appointment', component: CreateAppointmentComponent, canActivate: [AuthGuard] },
  { path: 'edit-appointment', component: EditAppointmentComponent, canActivate: [AuthGuard] },
  { path: 'direction-navigate', component: DirectionNavigateComponent, canActivate: [AuthGuard] },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'add-vendor', component: AgentComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: "/add-user",pathMatch:"full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { };
export const routingComponents = [AcquisitionsComponent, PricingInfoComponent, AddUserComponent, FollowUpComponent, AppointmentsComponent, AssignedLeadsComponent];
