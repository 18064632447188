import { getLocaleDayNames } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from 'buffer';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  // /api/get-users
  getSalesPersonList(): Observable<any> {
    // let url = "http://localhost:8082/api/get-sales-person";
    let url = environment.API_URL + 'api/get-sales-person';
    return this.http.post(url, {});
  }

  // get individual User data based on mobile number of user;
  getUsers(): Observable<any> {
    let url = environment.API_URL + 'api/user-details-web';
    let searchParams = new HttpParams();
    searchParams = searchParams.append('mobileNo', '8787111111');

    return this.http.get(url, { params: searchParams });
  }

  //add user to db
  addUser(data: any): Observable<any> {
    // console.log("Data being sent to api",data);
    //let url = environment.apiUrl + "api/add-user-web";
    let url = environment.API_URL + 'api/add-user-web';
    return this.http.post(url, {
      data,
    });
  }

  getProductCategories(): Observable<any> {
    // console.log("Data being sent to api",data);
    let url =
      environment.API_URL +
      'api/services';
      return this.http.get(url);
  }

  uploadImage(data: any, file: any): Observable<any> {
    //let url = environment.apiUrl + "api/upload-image";   //206.189.129.201
    //let url = "http://206.189.129.201:8081/" + "api/upload-image";
    let url = environment.API_URL + 'api/upload-image';
    console.log(file);
    let formsData: FormData = new FormData();
    formsData.append('photo', file);

    return this.http.post(url, formsData, {
      headers: new HttpHeaders({
        userId: data.userId,
        mobile: data.mobile,
        fileFormat: data.fileFormat,
        imageType: data.imageType,
        _method: 'POST',
      }),
    });
  }

  // getting all Pricing Details;
  getPricing(data: any): Observable<any> {
    let parsedData = JSON.parse(data);
    let url = environment.API_URL + 'api/category-pricing-web';

    return this.http.post(url, {
      userId: parsedData.userId,
      _method: 'POST',
    });
  }

  //get Categories of - to be used in Pricing Config
  getCategories(data: any): Observable<any> {
    let parsedData = JSON.parse(data);
    let url = environment.API_URL + 'api/categories-web';

    return this.http.post(url, {
      userId: parsedData.userId,
      _method: 'POST',
    });
  }

  //get Categories of - to be used in Pricing Config
  getAssignedLeads(
    data: any,
    name: string,
    skip: any,
    limit: any
  ): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };

    let url = environment.API_URL + 'api/search/lead-web'; //.env file

    // 165.232.183.50

    return this.http.post(url, {
      userId: parsedData.userId,
      // status:"ALL",
      leadStatus: ['ASSIGNED'],
      personIncharge: personIncharge,
      name: name,
      skip: skip,
      limit: limit,
      _method: 'POST',
    });
  }

  //get Categories of - to be used in Pricing Config
  getAcquisitionLeads(
    data: any,
    searchUserType: string,
    name: string,
    skip,
    limit
  ): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };

    let url = environment.API_URL + 'api/search/lead-web';

    return this.http.post(url, {
      leadStatus: ['ACQUISITION'],
      searchUserType: searchUserType,
      personIncharge: personIncharge,
      name: name,
      skip: skip,
      limit: limit,
      _method: 'POST',
    });
  }

  //get Categories of - to be used in Pricing Config
  getFollowUpLeads(
    data: any,
    searchUserType: string,
    name: string
  ): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };

    let url = environment.API_URL + 'api/search/lead-web';

    return this.http.post(url, {
      // userId : parsedData.userId,
      // status:"PENDING",
      leadStatus: ['FOLLOW-UP'],
      searchUserType: searchUserType,
      personIncharge: personIncharge,
      name: name,
      _method: 'POST',
    });
  }

  getAppointmentLeads(data: any, date: any): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };

    let url = environment.API_URL + 'api/search/lead-web';

    return this.http.post(url, {
      userId: parsedData.userId,
      // status:"ALL",
      leadStatus: ['APPOINTMENT'],
      personIncharge: personIncharge,
      appointmentDate: date,
      // toDate:date,
      // appointmentDate:"2021-08-29T18:30:00.000+00:00",
      _method: 'POST',
    });
  }

  searchAppointmentLeads(data: any, name: string): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };

    let url = environment.API_URL + 'api/search/lead-web';

    return this.http.post(url, {
      userId: parsedData.userId,
      // status:"ALL",
      leadStatus: ['APPOINTMENT'],
      personIncharge: personIncharge,
      name: name,
      // toDate:date,
      // appointmentDate:"2021-08-29T18:30:00.000+00:00",
      _method: 'POST',
    });
  }

  //SEARCH LEADS BASED ON NAME AND MOBILE
  searchLeads(data: any, search: string, leadStatus: string, userType: string): Observable<any> {
    let parsedData = JSON.parse(data);
    let personIncharge = {
      mobileNo: parsedData.mobile,
      name: parsedData.name,
      inchargeUserId: parsedData.userId,
    };
    let url = environment.API_URL + 'api/search/lead-web-name-mobile';
    let options: any = {};
    options.userId = parsedData.userId;
    options.personIncharge = personIncharge;
    options.search = search;
    options._method = 'POST';

    if (leadStatus != null) {
      options.leadStatus = [leadStatus];
    }

    if (userType) {
      options.userType = userType;
    }

    console.log('options', options);
    return this.http.post(url, options);
  }

  searchUserForEdit(data: any): Observable<any> {
    let url = environment.API_URL + 'api/search/lead-web';

    return this.http.post(url, {
      mobileNo: data,
    });
  }

  //add user to db
  editUser(data: any): Observable<any> {
    let url = environment.API_URL + 'api/update-lead-web';
    return this.http.post(url, {
      data,
    });
  }

  createAppointment(data: any): Observable<any> {
    let url = environment.API_URL + 'api/assign-sales-to-leads-web';
    return this.http.post(url, {
      data,
    });
  }

  unAssignLeadForSalesPerson(data: any): Observable<any> {
    let url = environment.API_URL + 'api/unassign-lead-web'; // env
    return this.http.post(url, {
      id: data,
    });
  }

  //before adding new User check if the Customer pre-exists
  verifyCustomerPreExists(data: any, mobile: any, city: any) {
    let parsedData = JSON.parse(data);
    let url = environment.API_URL + 'api/verify-customer';

    return this.http.post(url, {
      userId: parsedData.userId,
      mobile: mobile,
      city: city,
      _method: 'POST',
    });
  }
}
