import { getLocaleMonthNames } from '@angular/common';
import { Component, OnInit, ViewChild,ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';
import { convertUpdateArguments } from '@angular/compiler/src/compiler_util/expression_converter';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit, AfterViewInit {


  curDate = new Date();
  displayDate = this.curDate;
  month = this.curDate.getMonth();
  monthString = this.displayDate.toLocaleString('default', { month: 'short' });
  year = this.curDate.getFullYear();
  days: any = [];
  name: string = "";
  checkedDate!:boolean;
  noAppointments:boolean = false;
  search:string = "";


  @ViewChild('viewDate') viewDate : ElementRef<any>;

  ngAfterViewInit() {
    console.log('Values on ngAfterViewInit():');
    console.log("viewDate:", this.viewDate);
    // To scroll to the current date
    window.setTimeout(() => {
      const dateElements = document.getElementsByClassName('capDateId') as HTMLCollectionOf<HTMLInputElement>;
      for (let i = 0; i < dateElements.length; i++) {
        if (dateElements[i].checked) {
          dateElements[i].nextElementSibling.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
        }
      }
    }, 50);
  }
  constructor(private userService: UserService, private router: Router, private shared: SharedService,private spinner: NgxSpinnerService) { }

  appointmentsArr!: any;
  newArr!: any;
  // capDate:any = (new Date(this.curDate).toISOString());
  capDate!: any;
  modelDate!:any;


  ngOnInit(): void {

    this.capDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")

    this.getSalesPersonDetails();
    this.getAppointmentLeadsFromService(this.capDate);

    this.modelDate = moment(new Date()).format("YYYY-MM-DD")

    this.getDaysInMonth(this.year, this.month);

    this.shared.sendAppTitle("Appointments");

    console.log("What is modelDate",this.modelDate);

  }



  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };

  getAppointmentLeadsFromService(date: any) {
    this.spinner.show();
    let formDate = new Date(date);

    let selectedDate = moment(formDate).format("YYYY-MM-DDTHH:mm:ss");

    let convertibleDate = new Date(selectedDate);
    convertibleDate.setHours(12);
    convertibleDate.setMinutes(1);
    let selectedDateInUT = convertibleDate.toUTCString();
    let selectedDateInUTC = moment.utc(selectedDateInUT).format("YYYY-MM-DDTHH:mm:ss");


    this.userService.getAppointmentLeads(this.getSalesPersonDetails(), selectedDateInUTC).subscribe((data) => {
      this.spinner.hide();
      let appointmentsData = JSON.stringify(data.data);

      this.appointmentsArr = JSON.parse(appointmentsData);

      //display no Appointments if there are no appointments for any clicked date
      if(this.appointmentsArr.length > 0){
        this.noAppointments = false;
      }else {
        this.noAppointments = true;
      }

      this.appointmentsArr.forEach((x: any, i: number) => {
        if (x.appointmentDate == this.capDate) {
          this.newArr.push(x);
        }
      })

    })
  }

  searchAppointmentByName() {
    this.userService.searchAppointmentLeads(this.getSalesPersonDetails(), this.name).subscribe((data) => {
      let appointmentsData = JSON.stringify(data.data);

      this.appointmentsArr = JSON.parse(appointmentsData);


      this.appointmentsArr.forEach((x: any, i: number) => {
        if (x.appointmentDate == this.capDate) {
          this.newArr.push(x);
        }
      })

    })
  }

    // search appointments leads by name and mobile number
    searchLeads(){

      this.userService.searchLeads(this.getSalesPersonDetails(),this.search,"APPOINTMENT", null).subscribe((data) => {
        console.log("What is the new filter data",data);

        let appointmentsData = JSON.stringify(data.data);

        this.appointmentsArr = JSON.parse(appointmentsData);

      })
    }

  captureDate(data: any) {
    this.capDate = moment(data).format("YYYY-MM-DDTHH:mm:ss");

  }

  getDaysInMonth(year: any, month: any) {
    var date = new Date(year, month, 1);

    this.days = [];
    while (date.getMonth() === this.month) {
      this.days.push(moment(date).format("YYYY-MM-DD"));
      date.setDate(date.getDate() + 1);
    }
    console.log('this.days: ',this.days  );
    return this.days;
  }

  previousMonth() {

    this.appointmentsArr  =  [];
    let year = this.displayDate.getFullYear();

    if(this.month < 1){
      this.month = 11;
    } else {
      this.month = this.month - 1;
    }

    this.displayDate.setDate(1);
    this.displayDate.setMonth(this.month);
    this.monthString = this.displayDate.toLocaleString('default', { month: 'short' });
    console.log("selected day: ", this.displayDate);
    console.log("selected month: ", this.month);
    this.getDaysInMonth(year, this.month);
  }

  nextMonth() {
    this.appointmentsArr  =  [];
    let year = this.displayDate.getFullYear();

    if(this.month < 11){
      this.month = this.month + 1;
    } else {
      this.month = 0;
    }

    this.displayDate.setDate(1);
    this.displayDate.setMonth(this.month);
    this.monthString = this.displayDate.toLocaleString('default', { month: 'short' });
    console.log("selected day: ", this.displayDate);
    console.log("selected month: ", this.month);
    this.getDaysInMonth(year, this.month);

  }

  scrollCalendarLeft() {
    this.viewDate.nativeElement.scrollLeft -= (200);
    console.log("Scrolling Left")
  }

  scrollCalendarRight() {
    this.viewDate.nativeElement.scrollLeft += (200);
    console.log("Scrolling Right")
  }

  openEditComponent(data: any) {
    this.router.navigate(['edit', { "id": data._id, "mobileNo": data.mobileNo }]);
  }

  openEditAppointment(data: any) {
    this.router.navigate(['edit-appointment', { "id": data._id, "mobileNo": data.mobileNo }]);
  }

  unAssignLead(data: any) {
    // alert("Do you want to un-assign lead");
    if (confirm("Do you want to un-assign lead")) {
      this.userService.unAssignLeadForSalesPerson(data).subscribe((response) => {
        if (response.status == "Success") {
          alert("Un-assign Lead successful");
          // this.router.navigate(['assigned-leads']);
          window.location.reload();
        } else {
          alert("Un-assign Lead un-successful");
          window.location.reload();
        }
      })
    }
  }

  openNavigation(data:any){
    // console.log("what is the navigate to data",data);
    if(data.address.latitude != "" && data.address.longitude !="" && data.address.latitude && data.address.longitude){
      this.router.navigate(['direction-navigate',{"userLatitude":data.address.latitude,"userLongitude":data.address.longitude}]);
    } else {
      alert("Missing co-ordinates in user details");
    }
  }

}
