import { Component, OnInit,AfterViewInit,ElementRef,QueryList,ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { SharedService } from 'src/app/shared/shared.service';
import { UserService } from './../../services/user.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-acquisitions',
  templateUrl: './acquisitions.component.html',
  styleUrls: ['./acquisitions.component.scss']
})
export class AcquisitionsComponent implements OnInit {


  assignedArr: any = [];
  noData: boolean = true;
  name: string = "";
  mobile: string = "";
  skip = 0;
  limit = 10;
  observer:any;
  totalLeads:any;
  currentPage:any;
  search:string = "";
  status:string ="";
  usertype:string = "";
  @ViewChildren('triggerScroll', { read: ElementRef })
  triggerScroll: QueryList<ElementRef>;
  constructor(private userService: UserService, private router: Router, private shared: SharedService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.usertype = "Customer";
    this.getSalesPersonDetails();
    this.getLeads();
    this.intersectionObserver();

    //send page Title to header component
    this.shared.sendAppTitle("Acquisitons");

  }

  ngAfterViewInit() {
    this.triggerScroll.changes.subscribe((d) => {
      // console.log("listening triggerScroll",d.last);
      console.log("listening new triggerScroll",d.last);
      if (d.last) this.observer.observe(d.last.nativeElement);
    });
  }


  intersectionObserver() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (this.assignedArr.length < this.totalLeads) {
          this.skip = this.skip + this.limit;
          if (!this.search) {
            this.getLeads();
          }
        }
      }
    }, options);
  }
  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };

  getSalesDetails() {
    this.userService.getUsers().subscribe((data) => {
      // console.log("Am I getting the Data!!!!",data);
    })
  }

  getPricingDetails() {
    this.userService.getPricing(this.getSalesPersonDetails()).subscribe((data) => {
      // console.log("What is the pricing data",data);
    })
  }

  getCategories() {
    this.userService.getCategories(this.getSalesPersonDetails()).subscribe((data) => {
      // console.log("What is the pricing data",data);
    })
  }

  getSalesPersonList() {
    this.userService.getSalesPersonList().subscribe((data) => {
      // console.log("What is the pricing data",data);
    })
  }

  getLeads() {
    this.spinner.show();
    this.userService.getAcquisitionLeads(this.getSalesPersonDetails(),this.usertype, this.name, this.skip, this.limit).subscribe((data) => {
      console.log("What is the lead data", data);
      this.spinner.hide();
      this.totalLeads = data.total;
      let assignData = JSON.stringify(data.data);

      let parsedArr = JSON.parse(assignData);
      parsedArr.forEach((lead) => {
        this.assignedArr.push(lead);
      })
      console.log("What is the data?", this.assignedArr);
      this.noData = false;
    })
  }


  // search acquistions leads by name and mobile number
  searchLeads(){
    // this.spinner.show();
    this.userService.searchLeads(this.getSalesPersonDetails(),this.search,"ACQUISITION",this.usertype).subscribe((data) => {
      // console.log("What is the filter data",data);
      console.log("What is new filter data",data);
      // this.spinner.hide();
      let assignData = JSON.stringify(data.data);

      this.assignedArr = JSON.parse(assignData);

    })
  }

  onScroll() {
    console.log("This got scrolled");
  }

  openNavigation(data: any) {

    if (data.address.latitude != "" && data.address.longitude != "" && data.address.latitude && data.address.longitude) {
      this.router.navigate(['direction-navigate', { "userLatitude": data.address.latitude, "userLongitude": data.address.longitude }]);
    } else {
      alert("Missing co-ordinates in user details");
    }
  }

  customerVendorbtnClick()
  {
    //alert("click")
  }
  openEditComponent(data:any){
    this.router.navigate(['edit',{"id":data._id,"mobileNo":data.mobileNo}]);
  }
  changeUserType(event)
  {
    this.assignedArr = [];
    this.skip = 0;
    this.limit = 10;
    if (this.search) {
      this.searchLeads();
    } else {
      this.getLeads();
    }
  }
}
