<!-- <div class="map-style">
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false">
        </agm-marker>
        <agm-marker [latitude]="userLatitude" [longitude]="userLongitude" [markerDraggable]="false">
        </agm-marker>
    </agm-map>
</div> -->

<!-- {{userLatitude}}
{{userLongitude}} -->

<!-- <div class="map-style">
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
        <agm-polyline>
            <agm-polyline-point [latitude]="latitude" [longitude]="longitude">
                 </agm-polyline-point>
                 <agm-polyline-point [latitude]="userLatitude" [longitude]="userLongitude">
                 </agm-polyline-point>

        </agm-polyline>
    </agm-map>
</div> -->

<!-- <div class="col-md-12">
    <agm-map [latitude]="latitude" [longitude]="longitude"
          [fitBounds]="true">
          <agm-marker *ngFor="let coordinate of start_end_mark; let i=index" [latitude]="coordinate[0]"
                [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
          <agm-polyline [visible]="true" [strokeWeight]="7" [strokeColor]="'#07b57a'">
                <agm-polyline-point *ngFor="let coordinate of latlng; let i=index" [latitude]="coordinate[0]"
                      [longitude]="coordinate[1]">
                </agm-polyline-point>
          </agm-polyline>
    </agm-map>
</div> -->

<div class="full-width full-height scrollId">
    <div class="df full-width space-around" [hidden]="!mapLoaded">
        <div class="form-group flex-col">
            <label for="name" for="">Source:</label>
            <input class="inp" name="name" [(ngModel)]="sourceAddress" required autofocus type="text" #nameVal="ngModel" readonly>
        </div>
        <div class="form-group flex-col">
            <label for="name" for="">Destination:</label>
            <input class="inp" name="name" [(ngModel)]="destinationAddress" required autofocus type="text" #nameVal="ngModel" readonly>
        </div>
    </div>
    <div class="df align-center full-width" style="height: 400px" [hidden]="!mapLoaded">
        <div #map id="map-canvas"></div>
        <button class="btn" (click)="showMap()">Get Directions</button>
    </div>
    <div class="map-loader-container" *ngIf="!mapLoaded">
        <div class="map-loader"></div>
        <!-- <p>Please give location access for directions</p> -->
    </div>
    <!-- <button class="google-maps-button" (click)="openGoogleMaps()">Directions</button> -->
</div>