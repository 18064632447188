<div class="scrollId">
    <form action="" (ngSubmit)="onSubmit(addUserForm)" #addUserForm="ngForm">
        <div class="form1-align df space-around">
            <!-- <h3>Add User</h3> -->
            <div class="form-group flex-col">
                <label for="mobile" for="">Mobile Number <span style="color:red">*</span></label>
                <input id="mobile" name="mobileNo" maxlength="10" required [(ngModel)]="mobileNo"
                    (blur)="verifyMobileNo()" (keyup)="validMobileNo = true" autofocus type="text"
                    [class.error-class]="(formSubmitted && mobileVal.invalid)" #mobileVal="ngModel">

            </div>
            <div class="form-group flex-col">
                <label for="name" for="">Name <span style="color:red">*</span></label>
                <input id="name" name="name" [class.error-class]="(formSubmitted && nameVal.invalid)" [(ngModel)]="name" (blur) = "verifyName()" (keyup)="validName = true"
                 required autofocus type="text" #nameVal="ngModel">

            </div>

            <div class="form-group flex-col">
                <label for="address-type" for="">Address Type <span style="color:red">*</span></label>
                <select id="address-type" name="categories" ngModel autofocus required
                    [class.error-class]="(formSubmitted && categoryVal.invalid)" #categoryVal="ngModel">
                    <option value="" default selected>Choose a address type</option>
                    <option *ngFor="let category of categories" [ngValue]="category">{{category.name}}</option>
                </select>
            </div>
            <div class="form-group flex-col" style="margin-bottom: -7%;">
                <label for="status" for="">Status <span style="color:red">*</span></label>
                <ul class="donate-now">
                    <li class="radio-btn-lis rbl1">
                        <input type="radio" name="status" required value="INTERESTED" id="int"
                            style="width: auto !important;" [(ngModel)]='status' #statusVal="ngModel">
                        <label for="int" class="radio-btn-label" autofocus
                            [class.error-class]="(formSubmitted && statusVal.invalid)">INTERESTED</label>
                    </li>
                    <li class="radio-btn-lis rbl2">
                        <input type="radio" name="status" required value="NOTINTERESTED" id="notint"
                            [(ngModel)]='status' #statusVal="ngModel">
                        <label for="notint" class="radio-btn-label" autofocus
                            [class.error-class]="(formSubmitted && statusVal.invalid)">NOT INTERESTED</label>
                    </li>
                </ul>
            </div>

            <div class="form-div df auto-height">
                <div class="form-group flex-col no-margin full-width">
                    <label class="" for="formattedAddress" for="">Address Details <span
                            style="color:red">*</span></label>
                    <textarea id="address-details" name="formattedAddress" value={{formattedAddress}}
                        [(ngModel)]="formattedAddress" autofocus rows=5 cols=5 type="text"
                        (keydown.enter)="$event.preventDefault()" (blur)="searchForLocation(formattedAddress)"
                        placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                        #search required [class.error-class]="(formSubmitted && address.invalid)"
                        #address="ngModel"></textarea>
                </div>
                <div style="height: 10px; z-index: 9;">
                    <ul class="predictions-ul" *ngIf="showPredictions" style="background: #fff;">
                        <li class="predictions-li" *ngFor="let obj of areaPredictions" (click)="locationSelected(obj)">
                            {{obj.description}}
                        </li>
                    </ul>
                </div>
                <div class="form-group flex-col full-width no-margin">
                    <label for="landmark" for="">Landmark</label>
                    <input id="landmark" name="landmark" ngModel autofocus rows=5 cols=5>
                </div>
            </div>

            <div class="flex-col form-group">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)">
                    </agm-marker>
                </agm-map>
            </div>

            <div class="form-group flex-col">
                <label for="comment" for="">Comments</label>
                <textarea id="comments" name="comment" ngModel rows=5 cols=5></textarea>
            </div>
            <!-- <div class="form-group flex-col">
            <label for="otp" for="">Enter OTP</label>
            <input id="otp" name="otp" [(ngModel)]="otp" type="text">
            <button type="button" class="otp-btn" (click) = "sendOtp()">Send Otp</button>
        </div> -->

            <div class="form-group flex-col">
                <label for="email" for="">Email</label>
                <input id="email" name="email" type="email" (blur)="verifyEmail()" [(ngModel)]="email" (keyup)="validEmail=true">
                <div style="color:red" *ngIf="!validEmail &&email!=''">Not a valid email</div>
            </div>


        <div class="form-group flex-col">
            <label for="buildingImage" for="">Office or Building Picture <span style="color:red">*</span></label>
            <input id="buildingImage" name="buildingImage" type="file" accept="image/*" required
                [(ngModel)]="buildingImage" [class.error-class]="(formSubmitted && buildingImageVal.invalid)"
                #buildingImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="building">
        <div class="form-group flex-col">
            <label for="signageImage" for="">Signage Picture <span style="color:red">*</span></label>
            <input id="signageImage" name="signageImage" type="file" accept="image/*" required
                [(ngModel)]="signageImage" [class.error-class]="(formSubmitted && signageImageVal.invalid)"
                #signageImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="sign">




        <div class="form-group flex-col">

            <label for="address-type" for="">Vendor Type <span style="color:red">*</span></label>
            <ul class="donate-now">
                <li class="radio-btn-lis rbl1">
                    <input type="radio" name="status" required value="INDIVIDUAL" id="int"
                        style="width: auto !important;" [(ngModel)]='status' #statusVal="ngModel">
                    <label for="int" class="radio-btn-label" autofocus
                        [class.error-class]="(formSubmitted && statusVal.invalid)">individual</label>
                </li>
                <li class="radio-btn-lis rbl2">
                    <input type="radio" name="status" required value="ORGANIZATION" id="notint"
                        [(ngModel)]='status' #statusVal="ngModel">
                    <label for="notint" class="radio-btn-label" autofocus
                        [class.error-class]="(formSubmitted && statusVal.invalid)">Organization</label>
                </li>
            </ul>

        </div>

        <div class="form-group flex-col">
            <label for="address-type" for=""> List of services <span style="color:red">*</span></label>
            <select id="address-type" name="categories" ngModel autofocus required>
                <option value="" default>Select Frequency</option>
                <option value="OneTime">individual</option>
                <option value="Weekly">Organization</option>

            </select>
        </div>

        <div class="form-group flex-col align-inputs" *ngIf="status == 'iINTERESTED'">
            <label for="frequency" for="">Select Frequency</label>
            <select name="frequency" id="frequency" ngModel>
                <option value="" default>Select Frequency</option>
                <option value="OneTime">One Time</option>
                <option value="Weekly">Weekly</option>
                <option value="Fortnightly">Fortnightly</option>
                <option value="Monthly">Monthly</option>
            </select>
        </div>
        <div class="form-group flex-col align-inputs" *ngIf="status == 'iNOTINTERESTED'">
            <label for="sub-status" for="">Sub status<span style="color:red">*</span></label>
            <select id="sub-status" name="subStatus" ngModel autofocus required
                [class.error-class]="(formSubmitted && subStatusVal.invalid)" #subStatusVal="ngModel">
                <option value="" default selected>Select sub status</option>
                <option value="Price Mismatch">Price Mismatch</option>
                <option value="No adequate scrap type">No adequate scrap type</option>
                <option value="Less scrap quantity">Less scrap quantity</option>
                <option value="Tied up with other vendor">Tied up with other vendor</option>
                <option value="Asked to call later">Asked to call later</option>
                <option value="Others">Others</option>
            </select>
        </div>





        <div class="form-group flex-col align-inputs" *ngIf="status == 'INTERESTED'">
            <label for="buildingImage" for="">Aadhar number <span style="color:red">*</span></label>
            <input id="buildingImage" name="buildingImage" type="file" accept="image/*" required
                [(ngModel)]="buildingImage" [class.error-class]="(formSubmitted && buildingImageVal.invalid)"
                #buildingImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="building">
        <div class="form-group flex-col flex-col align-inputs" *ngIf="status == 'INTERESTED'">
            <label for="signageImage" for="">  PAN Card Number <span style="color:red">*</span></label>
            <input id="signageImage" name="signageImage" type="file" accept="image/*" required
                [(ngModel)]="signageImage" [class.error-class]="(formSubmitted && signageImageVal.invalid)"
                #signageImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="sign">

        <div class="form-group flex-col align-inputs" *ngIf="status == 'NOTINTERESTED'">
            <label for="buildingImage" for="">Aadhar number <span style="color:red">*</span></label>
            <input id="buildingImage" name="buildingImage" type="file" accept="image/*" required
                [(ngModel)]="buildingImage" [class.error-class]="(formSubmitted && buildingImageVal.invalid)"
                #buildingImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="building">
        <div class="form-group flex-col flex-col align-inputs" *ngIf="status == 'NOTINTERESTED'">
            <label for="signageImage" for="">  PAN Card Number <span style="color:red">*</span></label>
            <input id="signageImage" name="signageImage" type="file" accept="image/*" required
                [(ngModel)]="signageImage" [class.error-class]="(formSubmitted && signageImageVal.invalid)"
                #signageImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="sign">
        <div class="form-group flex-col flex-col align-inputs" *ngIf="status == 'NOTINTERESTED'">
            <label for="signageImage" for="">  GST Number <span style="color:red">*</span></label>
            <input id="signageImage" name="signageImage" type="file" accept="image/*" required
                [(ngModel)]="signageImage" [class.error-class]="(formSubmitted && signageImageVal.invalid)"
                #signageImageVal="ngModel" autofocus (change)="imgChanged($event)">
        </div>
        <img src="" alt="" style="display: none;" class="sign">

</div>  <!--form1-align df space-around-->
        </form> <!--form end-->
    </div> <!--scrollId-->
