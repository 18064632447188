<section class="login-page">
  <div class="login-wrapper">
    <div class="card-style">
      <div class="card-body-style">
        <form action="" (ngSubmit)="onSubmit(signInForm)" #signInForm="ngForm">

          <div class="row">
            <div class="img-style">
              <img class="logo-img" src="./../../../assets/images/logo_login_glow.svg" alt="">
            </div>
            <div class="form-group flex-col input-style">
              <input id="mobile" placeholder="Registered Mobile Number" (blur)="verifyMobileNo()"   maxlength="10"  type="text" name="phone" [(ngModel)]="phone" required>
              <div style="color:red; text-align: center;" *ngIf="!validMobileNo">Invalid mobile number</div>
            </div>

            <div class="form-group flex-col input-style">
              <input id="password" placeholder="Password" type="password" name="password" ngModel required class="mb-2">
              <div class="error-msg" *ngIf="formSubmitted && !isFormValid">Invalid credentials</div>
            </div>
            <button class="submit-btn" type="submit">Sign in</button>
            <button style="background-color: transparent; color: red; border: none; font-weight: 500; margin-top: 15px;"
            (click)="router.navigate(['/reset-password']);">Reset Password ?</button>
            <div style="text-align: center;
                      height: 8rem;margin-bottom: 1rem;">
              <img src="./../../../assets/images/login_illustration.svg" alt="">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
