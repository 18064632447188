<div class="scrollId">

  <form action="" (ngSubmit)="onSubmit(addUserForm)" #addUserForm="ngForm">
    <div class="form1-align df space-around">
      <div class="form-group flex-col">
        <label for="usertype" for="">User Type <span style="color:red">*</span></label>
        <select name="usertype" id="usertype" [(ngModel)]='usertype' autofocus required
          [class.error-class]="(formSubmitted && usertypeVal.invalid)" #usertypeVal="ngModel">
          <option value="" default>Select User Type</option>
          <option value="Customer">Customer</option>
          <option value="Partner">Partner</option>
          <option value="Agent">REO</option>
          <option value="Vendor">Recycler</option>
        </select>
      </div>

      <div class="form-group flex-col">
        <label for="mobile" for="">Mobile Number <span style="color:red">*</span></label>


        <input id="mobile" name="mobileNo" maxlength="10" required [(ngModel)]="mobileNo" (blur)="verifyMobileNo()"
          (keyup)="validMobileNo = true" autofocus type="text"
          [class.error-class]="(formSubmitted && mobileVal.invalid)" #mobileVal="ngModel" disabled>

      </div>
      <div class="form-group flex-col">
        <label for="name" for="">Name <span style="color:red">*</span></label>
        <input id="name" name="name" [class.error-class]="(formSubmitted && nameVal.invalid)" [(ngModel)]="name"
          (blur)="verifyName()" (keyup)="validName = true" required autofocus type="text" #nameVal="ngModel">

      </div>

      <div class="form-group flex-col" style="margin-bottom: -7%;">
        <label for="status" for="">Status <span style="color:red">*</span></label>
        <ul class="donate-now">
          <li class="radio-btn-lis rbl1">
            <input type="radio" name="status" required value="INTERESTED" id="int" style="width: auto !important;"
              [(ngModel)]='status' #statusVal="ngModel">
            <label for="int" class="radio-btn-label" autofocus
              [class.error-class]="(formSubmitted && statusVal.invalid)">INTERESTED &nbsp; <i class="fa fa-check"
                aria-hidden="true" style="font-size:16px; color:#8BC53F;" *ngIf="status == 'INTERESTED'"></i> </label>

          </li>
          <li class="radio-btn-lis rbl2">
            <input type="radio" name="status" required value="NOTINTERESTED" id="notint" [(ngModel)]='status'
              #statusVal="ngModel">
            <label for="notint" class="radio-btn-label" autofocus
              [class.error-class]="(formSubmitted && statusVal.invalid)">NOT INTERESTED &nbsp; <i class="fa fa-check"
                aria-hidden="true" style="font-size:16px; color:#8BC53F;" *ngIf="status == 'NOTINTERESTED'"></i></label>
          </li>
        </ul>
      </div>

      <div class="form-div df auto-height">
        <div class="form-group flex-col no-margin full-width">
          <label class="" for="formattedAddress" for="">Address Details <span style="color:red">*</span> <span
              class="plus">+</span></label>
          <textarea id="address-details" name="formattedAddress" value={{formattedAddress}}
            [(ngModel)]="formattedAddress" autofocus rows=5 cols=5 type="text" (keydown.enter)="$event.preventDefault()"
            (blur)="searchForLocation(formattedAddress)" placeholder="Search Nearest Location" autocorrect="off"
            autocapitalize="off" spellcheck="off" #search required
            [class.error-class]="(formSubmitted && address.invalid)" #address="ngModel"></textarea>
        </div>
        <div style="height: 10px; z-index: 9;">
          <ul class="predictions-ul" *ngIf="showPredictions" style="background: #fff;">
            <li class="predictions-li" *ngFor="let obj of areaPredictions" (click)="locationSelected(obj)">
              {{obj.description}}
            </li>
          </ul>
        </div>

        <div class="form-group flex-col full-width no-margin">

          <label for="landmark" for="">Flat No<span style="color:red">*</span></label>
          <!-- <label for="landmark" for="">FLOT NO</label> -->
          <input id="landmark" name="landmark" [(ngModel)]="landmark" required autofocus rows=5 cols=5
            [class.error-class]="(formSubmitted && flotNoVal.invalid)" #flotNoVal="ngModel">

        </div>
      </div>

      <div class="flex-col form-group">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
          <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)">
          </agm-marker>
        </agm-map>
      </div>

      <div class="form-group flex-col" *ngIf="usertype == 'Customer'">
        <label for="address-type" for="">Address Type <span style="color:red">*</span></label>
        <select id="address-type" name="categories" [(ngModel)]='editCategory' autofocus required
          [class.error-class]="(formSubmitted && categoryVal.invalid)" #categoryVal="ngModel">
          <option value="" default selected>Choose a address type</option>
          <option *ngFor="let category of categories" [ngValue]="category">{{category.name}}</option>
        </select>
      </div>

      <div class="form-group flex-col ">

        <label for="email" for="">Email<span style="color:red">*</span> </label>
        <input id="email" name="email" type="email" (blur)="verifyEmail()" [(ngModel)]="email" (keyup)="validEmail=true"
          [class.error-class]="(formSubmitted && emailVal.invalid)" required autofocus #emailVal="ngModel">
        <div style="color:red" *ngIf="!validEmail &&email!=''">Not a valid email</div>
      </div>


      <div class="form-group flex-col">
        <label for="pannumber" for="">PAN Number</label>
        <input id="pannumber" name="pannumber" [(ngModel)]="pannumber" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && pannumberVal.invalid)" #pannumberVal="ngModel">

      </div>

      <div class="form-group flex-col">
        <label for="gstnumber" for=""> GST Number</label>
        <input id="gstnumber" name="gstnumber" [(ngModel)]="gstnumber" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && gstnumberVal.invalid)" #gstnumberVal="ngModel">

      </div>

      <div class="form-group flex-col">
        <label for="upinumber" for="">UPI ID</label>
        <input id="upinumber" name="upinumber" [(ngModel)]="upinumber" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && upinumberVal.invalid)" #upinumberVal="ngModel">

      </div>

      <div class="form-group flex-col">
        <label for="otp" for="">Aadhar Number</label>
        <input id="aadhar" name="aadhar" [(ngModel)]="aadhar" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && aadharVal.invalid)" #aadharVal="ngModel">

      </div>

      <div class="form-group flex-col">
        <label for="otp" for="">Bank A/C No</label>
        <input id="accountNumber" name="accountNumber" [(ngModel)]="accountNumber" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && bankdetailsVal.invalid)" #bankdetailsVal="ngModel">

      </div>


      <div class="form-group flex-col">
        <label for="mobile" for=""> IFSC code</label>
        <input id="ifsc" name="ifsc" [(ngModel)]="ifsc" autofocus rows=5 cols=5
          [class.error-class]="(formSubmitted && ifscVal.invalid)" #ifscVal="ngModel">
      </div>

      <div class="form-group flex-col" *ngIf="status == 'INTERESTED' && usertype == 'Customer'">

        <label for="frequency" for="">Select Frequency</label>
        <select name="frequency" id="frequency" [(ngModel)]='editFrequency' autofocus required
          [class.error-class]="(formSubmitted && frequencyVal.invalid)" #frequencyVal="ngModel">
          <option value="" default>Select Frequency</option>
          <option value="OneTime">One Time</option>
          <option value="Weekly">Weekly</option>
          <option value="Fortnightly">Fortnightly</option>
          <option value="Monthly">Monthly</option>
        </select>
      </div>
      <div class="form-group flex-col"
        *ngIf="status == 'NOTINTERESTED'">

        <label for="sub-status" for="">Sub status<span style="color:red">*</span></label>
        <select id="sub-status" name="subStatus" [(ngModel)]='editSubStatus' autofocus required
          [class.error-class]="(formSubmitted && subStatusVal.invalid)" #subStatusVal="ngModel">
          <option value="" default selected>Select sub status</option>
          <option value="Price Mismatch">Price Mismatch</option>
          <option value="Tied up with other vendor">Tied up with other vendor</option>
          <option value="Asked to call later">Asked to call later</option>
          <option value="Others">Others</option>
        </select>
      </div>

      <div class="form-group flex-col">

      </div>

    </div> <!--form1-align df space-around-->

    <div class="row form2-align  df space-around">
      <h6 class="my-3"> Pictures & Documents </h6>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isBuilding ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">

        <div class="card ccard ">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{building?building:'./../../../assets/images/dummy.png'}}" alt=""
                class="building pdimares" alt="">
            </div>
            <div style="width: 50%;">
              <span class="cctit">Building Picture</span>
              <span class="cctitsub"> {{ (buildingImage.length>19)? (buildingImage | slice:0:19)+'..':(buildingImage)
                }}</span>
            </div>

            <div style="width:15%; padding: 0px;">

              <div class="imagee-upload">
                <label for="buildingImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="buildingImage"
                  id="buildingImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isSign ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">

        <div class="card ccard">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{sign?sign:'./../../../assets/images/dummy.png'}}" alt=""
                class="sign pdimares">
            </div>
            <div style="width: 50%;">

              <span class="cctit">Signage Picture </span>
              <span class="cctitsub"> {{ (signageImage.length>19)? (signageImage | slice:0:19)+'..':(signageImage)
                }}</span>
            </div>

            <div style="width:15%; padding: 0px;">


              <div class="imagee-upload">
                <label for="signageImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="signageImage"
                  id="signageImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">

              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isPan ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">


        <div class="card ccard">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{pan?pan:'./../../../assets/images/dummy.png'}}" alt="" class="pan pdimares">
            </div>
            <div style="width: 50%;">

              <span class="cctit">Pan Card</span>
              <span span class="cctitsub"> {{ (pancardImage.length>19)? (pancardImage | slice:0:19)+'..':(pancardImage)
                }}</span>
            </div>

            <div style="width:15%; padding: 0px;">


              <div class="imagee-upload">
                <label for="pancardImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="pancardImage"
                  id="pancardImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">

              </div>

            </div>
          </div>
        </div>


      </div>

      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isGst ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">

        <div class="card ccard">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{gst?gst:'./../../../assets/images/dummy.png'}}" alt="" class="gst pdimares">
            </div>
            <div style="width: 50%;">

              <span class="cctit">GST</span>
              <span span class="cctitsub">{{ (gstImage.length>19)? (gstImage | slice:0:19)+'..':(gstImage) }}</span>
            </div>

            <div style="width:15%; padding: 0px;">

              <div class="imagee-upload">
                <label for="gstImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="gstImage"
                  id="gstImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">

              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isPrice ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">
        <div class="card ccard">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{price?price:'./../../../assets/images/dummy.png'}}" alt=""
                class="price pdimares">
            </div>
            <div style="width: 50%;">

              <span class="cctit">Pricing List</span><br>
              <span class="cctitsub"> {{ (priceImage.length>19)? (priceImage | slice:0:19)+'..':(priceImage) }}</span>
            </div>

            <div style="width:15%; padding: 0px;">

              <div class="imagee-upload">
                <label for="priceImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="priceImage"
                  id="priceImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4 col-md-4 col-lg-4" [ngClass]="isOther ? 'borderCls' : ''"
        style="margin: -16px 0px 0px 0px;">
        <div class="card ccard">
          <div class="row no-gutters align-items-center">
            <div style="width: 35%;">
              <img class="card-img" src="{{other?other:'./../../../assets/images/dummy.png'}}" alt=""
                class="other pdimares">
            </div>
            <div style="width: 50%;">

              <span class="cctit">Others</span>
              <span class="cctitsub"> {{ (otherImage.length>19)? (otherImage | slice:0:19)+'..':(otherImage) }}</span>
            </div>

            <div style="width:15%; padding: 0px;">

              <div class="imagee-upload">
                <label for="otherImage">
                  <i class="fa fa-camera uploaded-image" style="font-size:20px;color:#8BC53F;"></i>
                </label>

                <input data-required="image" type="file" accept="image/pdf" capture="camera" name="otherImage"
                  id="otherImage" class="image-input" data-traget-resolution="image_resolution" value=""
                  (change)="imgChanged($event)">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row form2-align  df space-around">

      <label for="comment" for="">Comments</label>
      <textarea id="comments" name="comment" ngModel="{{comment}}" rows=5 cols=15></textarea>

    </div>

    <hr>
    <div class="row btn-align">
      <button type="submit" class="save-btn">Save</button>
      <button type="button" class="cancel-btn" (click)="onCancel()">Cancel</button>
    </div>
  </form> <!--form end-->
  <div class="moip"></div>
</div>
