import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ControllerModule } from './controllers/controller.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule} from './material/material.module'
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginComponent } from './shared/login/login.component';
import { JwtInterceptor } from './helpers/JwtInterceptor';
import { ErrorInterceptor } from './helpers/ErrorInterceptor';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    AppRoutingModule,
    SharedModule,
    ControllerModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    // delete,
    // MainModule,
    // AgmCoreModule.forRoot({
    //   apiKey:""
    // })
  ],
  // providers: [{provide: LocationStrategy,useClass:HashLocationStrategy}],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
