import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './../app-routing.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaterialModule } from '../material/material.module';
// import { SharedRoutingModule } from './shared.routing.module';




@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    // SharedRoutingModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent
  ]
})
export class SharedModule { }
