import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    loginStatus = localStorage.getItem("salesPerson");

  constructor(private authenticationService: AuthenticationService, private router: Router) {

    
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authenticationService.isAuthenticated) {
          return true;
        }
        
        
    //if userDetails present in localStorage, it means user did not logout and thus marking authenticated as true
    if(this.loginStatus){
      console.log("login is true");
        return true;
    } 
    
    // this.router.navigate(['']);
    return false;
  }

}