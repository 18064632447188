import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  search:string = "";
  userDataArr!:any;
  noData: boolean = true;

  constructor(private userService: UserService, private shared: SharedService) { }

  ngOnInit(): void {

    this.shared.sendAppTitle("Search Leads");
    this.getSalesPersonDetails();
  }

  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };

  getUserDetails(){

    console.log("this search length",this.search.length);
    if(this.search.length > 0){
      this.userService.searchLeads(this.getSalesPersonDetails(),this.search,null,null).subscribe((data) => {
        let userData = JSON.stringify(data.data);
        this.userDataArr = JSON.parse(userData) ;
        this.noData = false;
      })
    }else{
      this.userDataArr = [];
    }
  }
}
