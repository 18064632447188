import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  phone!: string;
  oldPassword!: string;
  newPassword!: string;
  encryptData!: string;

  isFormValid: boolean = true;
  formSubmitted!: boolean;
  validMobileNo: boolean = true;

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  onSubmit(resetPasswordForm: NgForm) {
    this.formSubmitted = true;
    // Validate number again
    
    if (resetPasswordForm.valid) {

      const phone = this.phone;
      if (isNaN(Number(phone)) || phone.length !== 10) {
        console.error('Invalid mobile number');
        return;
      }
      
      this.isFormValid = true;
      const resetPasswordData = resetPasswordForm.value;
      resetPasswordData.mobileNo = resetPasswordData.phone;

      if (resetPasswordData.newPassword !== resetPasswordData.confirmPassword) {
        alert("Confirm password is not matching with the new password");
        return;
      }

      this.authenticationService
        .resetPassword(resetPasswordData)
        .subscribe((res: any) => {
          if (res.status === 'Success') {
            console.log('res is : ', res);
            alert('Password is changed. Please login!');
            this.authenticationService.logout();
            this.router.navigate(['login']);
          } else if(res.message === "Incorrect password") {
            this.isFormValid = false;
          } else {
            alert('Unable to reset password');
          }
        });
    } else {
      alert('Please add the required fields');
    }
  }

  verifyMobileNo() {
    // check if the mobile number entered is valid
    let chkNum = /^\d+$/.test(this.phone);
    if (!chkNum || this.phone.length !== 10) {
      this.validMobileNo = false;
      return;
    } else {
      this.validMobileNo = true;
    }
  }
}
