import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss'],
})
export class FollowUpComponent implements OnInit {
  assignedArr!: any;
  name: string = '';
  search: string = '';
  usertype: string = '';
  constructor(
    private userService: UserService,
    private router: Router,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.usertype = 'Customer';
    this.getSalesPersonDetails();
    this.getLeads();

    //send page Title to header component
    this.shared.sendAppTitle('Follow Up');
  }

  getSalesPersonDetails() {
    return localStorage.getItem('salesPerson');
  }

  getLeads() {
    this.userService
      .getFollowUpLeads(this.getSalesPersonDetails(), this.usertype, this.name)
      .subscribe((data) => {
        let assignData = JSON.stringify(data.data);
        this.assignedArr = JSON.parse(assignData);
      });
  }

  // search follow-up leads by name and mobile number
  searchLeads() {
    this.userService
      .searchLeads(
        this.getSalesPersonDetails(),
        this.search,
        'FOLLOW-UP',
        this.usertype
      )
      .subscribe((data) => {
        console.log('What is the new filter data', data);

        let assignData = JSON.stringify(data.data);

        this.assignedArr = JSON.parse(assignData);
      });
  }

  openEditComponent(data: any) {
    this.router.navigate(['edit', { id: data._id, mobileNo: data.mobileNo }]);
  }

  openCreateAppointment(data: any) {
    this.router.navigate(['create-appointment', { id: data._id }]);
  }

  unAssignLead(data: any) {
    if (confirm('Do you want to un-assign lead')) {
      this.userService
        .unAssignLeadForSalesPerson(data)
        .subscribe((response) => {
          if (response.status == 'Success') {
            alert('Un-assign Lead successful');
            // this.router.navigate(['assigned-leads']);
            window.location.reload();
          } else {
            alert('Un-assign Lead un-successful');
            window.location.reload();
          }
        });
    }
  }

  openNavigation(data: any) {
    // console.log("what is the navigate to data",data);
    if (
      data.address.latitude != '' &&
      data.address.longitude != '' &&
      data.address.latitude &&
      data.address.longitude
    ) {
      this.router.navigate([
        'direction-navigate',
        {
          userLatitude: data.address.latitude,
          userLongitude: data.address.longitude,
        },
      ]);
    } else {
      alert('Missing co-ordinates in user details');
    }
  }

  changeUserType(event) {
    this.assignedArr = [];
    if (this.search) {
      this.searchLeads();
    } else {
      this.getLeads();
    }
  }
}
