import { ChangeDetectorRef, Component, OnInit,Output,EventEmitter } from '@angular/core';
import { SharedService } from './../shared.service';
import { Router } from '@angular/router';
import { SalesPersonService } from 'src/app/services/sales-person.service';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  //sent Logout status to app component
  @Output() loggedInStatus : EventEmitter<boolean> = new EventEmitter();
  activeHeader!: any;
  salesPersonInfo!:any;
  name!:any;
  // appTitleInHeader!:any;
  constructor(private router: Router, private shared: SharedService, private salesPersonService: SalesPersonService, public authenticationService: AuthenticationService,private cd : ChangeDetectorRef) {
    // Injecting header component to authentication service
    authenticationService.headerComponent = this;
   }


  toggleSideBar(): void {
    // this.sideNav = !this.sideNav;
    this.shared.toggleSideNav("toggleSideBar");
  }

  logout() {
    localStorage.removeItem("salesPerson");
    this.authenticationService.logout();
    this.loggedInStatus.emit(false); // send logout status to app component to display login page
  }

  emitLoggedInStatus() {
    this.loggedInStatus.emit(false); // send logout status to app component to display login page
  }

  ngOnInit(): void {
    // this.activeHeader = "Appointments";

    this.salesPersonInfo = this.getSalesPersonDetails();

    this.getName(this.salesPersonInfo);

    //get active page title header
   this.shared.receiveAppTitle().subscribe((title) => {
      this.activeHeader = title;
      this.cd.detectChanges();
    });
    // this.activeHeader = this.shared.headerTitle;
  }

  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };

  getName(data:any){
    let parsedData = JSON.parse(data);
    this.name = parsedData.name;
  }




}
