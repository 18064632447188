<mat-sidenav-container>
  <mat-sidenav
    [ngClass]="{'class1':authenticationService.isAuthenticated,'class2':!authenticationService.isAuthenticated}"
    [mode]="mode" [(opened)]="sideNav" (click)="scrWidth <= 1200 && toggleSideBarMobile()">
    <ul class="sidenav-ul" id="accordion">
      <li class="sidenav-li">
        <a class="sidenav-a nofocus" routerLink="/assigned-leads" routerLinkActive="active">
          <img class="sidenav-img" src="./../../../assets/images/nav-02xxxhdpi.png" alt="">
          <label class="sidenav-span">Assigned Leads</label>
          <i class="arrow arw-right"></i>
        </a>
      </li>
      <li class="sidenav-li">
        <a class="sidenav-a" routerLink="/appointments" routerLinkActive="active">
          <img class="sidenav-img" src="./../../../assets/images/nav-03xxxhdpi.png" alt="">
          <span class="sidenav-span">Appointments</span>
          <i class="arrow arw-right"></i>
        </a>
      </li>
      <li class="sidenav-li">
        <a class="sidenav-a" routerLink="/add-user" routerLinkActive="active">
          <img class="sidenav-img" src="./../../../assets/images/nav-01xxxhdpi.png" alt="">
          <span class="sidenav-span">Add User</span>
          <i class="arrow arw-right"></i>
        </a>
      </li>

      <li class="sidenav-li collapsed" data-bs-toggle="collapse" href="#collapseThree">
        <a class="sidenav-a" routerLink="/acquisition" routerLinkActive="active">
          <img class="sidenav-img" src="./../../../assets/images/nav-04xxxhdpi.png" alt="">
          <span class="sidenav-span">Acquisition</span>
          <i class="arrow arw-right"></i>
        </a>

      </li>

      <li class="sidenav-li">
        <a class="sidenav-a " routerLink="/follow-up" routerLinkActive="active">
          <img class="sidenav-img" src="./../../../assets/images/nav-05xxxhdpi.png" alt="">
          <span class="sidenav-span">Follow Up</span>
          <i class="arrow arw-right"></i>
        </a>

      </li>
      <li class="sidenav-li">
        <a class="sidenav-a" routerLink="/pricing-info" routerLinkActive="active">
          <img class="sidenav-img" style="width: 15px;" src="./../../../assets/images/nav-06xxxhdpi.png" alt="">
          <span class="sidenav-span"> Pricing info</span>
          <i class="arrow arw-right"></i>
        </a>
      </li>
      <li class="sidenav-li">
        <a class="sidenav-a" routerLink="/search" routerLinkActive="active">
          <img class="sidenav-img" style="width: 15px;height: 30px!important;"
            src="./../../../assets/images/nav-07xxxhdpi.png" alt="">
          <span class="sidenav-span"> Search Leads</span>
          <i class="arrow arw-right"></i>
        </a>
      </li>

    </ul>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{'class3':authenticationService.isAuthenticated,'class4':!authenticationService.isAuthenticated}">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
