import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assigned-leads',
  templateUrl: './assigned-leads.component.html',
  styleUrls: ['./assigned-leads.component.scss'],
})
export class AssignedLeadsComponent implements OnInit {
  @ViewChildren('triggerScroll', { read: ElementRef })
  triggerScroll: QueryList<ElementRef>;

  constructor(
    private userService: UserService,
    private router: Router,
    private shared: SharedService,
    private spinner: NgxSpinnerService
  ) {}

  assignedArr: any = [];
  name: string = '';
  search: string = '';
  skip = 0;
  limit = 10;
  observer: any;
  totalLeads: any;

  ngOnInit(): void {
    this.getSalesPersonDetails();
    // this.name = "pal";
    this.getAssignedLeadsFromService();
    this.intersectionObserver();

    //send page Title to header component
    this.shared.sendAppTitle('Assigned Leads');
  }

  ngAfterViewInit() {
    this.triggerScroll.changes.subscribe((d) => {
      // console.log("listening triggerScroll",d.last);
      console.log('listening new triggerScroll', d.last);
      if (d.last) this.observer.observe(d.last.nativeElement);
    });
  }

  intersectionObserver() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (this.assignedArr.length < this.totalLeads) {
          this.skip = this.skip + this.limit;
          if (!this.search) {
            this.getAssignedLeadsFromService();
          }
        }
      }
    }, options);
  }
  getSalesPersonDetails() {
    return localStorage.getItem('salesPerson');
  }

  getAssignedLeadsFromService() {
    this.spinner.show();
    this.userService
      .getAssignedLeads(
        this.getSalesPersonDetails(),
        this.name,
        this.skip,
        this.limit
      )
      .subscribe((data) => {
        // console.log("What is the pricing data",data);
        this.spinner.hide();
        this.totalLeads = data.total;
        let assignData = JSON.stringify(data.data);

        let parsedArr = JSON.parse(assignData);
        parsedArr.forEach((lead) => {
          this.assignedArr.push(lead);
        });
        console.log('What is the data?', this.assignedArr);
      });
  }

  // search assigned leads by name and mobile number
  searchLeads() {
    this.userService
      .searchLeads(this.getSalesPersonDetails(), this.search, 'ASSIGNED', null)
      .subscribe((data) => {
        console.log('What is the new filter data', data);

        let assignData = JSON.stringify(data.data);

        this.assignedArr = JSON.parse(assignData);
      });
  }

  openEditComponent(data: any) {
    this.router.navigate(['edit', { id: data._id, mobileNo: data.mobileNo }]);
  }

  openCreateAppointment(data: any) {
    this.router.navigate(['create-appointment', { id: data._id }]);
  }

  unAssignLead(data: any) {
    if (confirm('Do you want to un-assign lead')) {
      this.userService
        .unAssignLeadForSalesPerson(data)
        .subscribe((response) => {
          if (response.status == 'Success') {
            alert('Un-assign Lead successful');
            // this.router.navigate(['assigned-leads']);
            window.location.reload();
          } else {
            alert('Un-assign Lead un-successful');
            window.location.reload();
          }
        });
    }
  }

  openNavigation(data: any) {
    if (
      data.address.latitude != '' &&
      data.address.longitude != '' &&
      data.address.latitude &&
      data.address.longitude
    ) {
      this.router.navigate([
        'direction-navigate',
        {
          userLatitude: data.address.latitude,
          userLongitude: data.address.longitude,
        },
      ]);
    } else {
      alert('Missing co-ordinates in user details');
    }
  }
}
