import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { UserService } from './../../services/user.service'

@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.scss']
})
export class PricingInfoComponent implements OnInit {

  constructor(private userService: UserService, private shared: SharedService) { }

  pricingList!: any;
  categories!: any;
  category!: any;

  ngOnInit(): void {
    this.getSalesPersonDetails();
    this.getCategories();
    this.getPricingDetails("Residence");
    // this.getCategoryBasedPricingDetails("Residence");
    //send page Title to header component
    this.shared.sendAppTitle("Pricing Info");
  }
  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };



  getCategories() {
    this.userService.getCategories(this.getSalesPersonDetails()).subscribe((res: any) => {
      if (res.status == "Success" && res.data.data.categories.length > 0) {
        this.categories = res.data.data.categories;
      }
    });
  }


  getPricingDetails(category: any) {
    console.log("Categories changed",category);
    this.userService.getPricing(this.getSalesPersonDetails()).subscribe((data) => {
      // console.log("What is the pricing data", data.data.data.categories);
      let pricingData = JSON.stringify(data.data.data.categories);
      let categoryList = JSON.parse(pricingData);
      let filterArray = []
      categoryList.forEach(x => {
        if (category == x.consumercategoryname) {
          filterArray.push(x);
        };
      });

      this.pricingList = filterArray;
    })
  }

  // getCategoryBasedPricingDetails(category:any){
  //   this.pricingList.forEach(x => {
  //     console.log("What is x",x.consumercategoryname);
  //   });
  // }
}
