import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SalesPerson } from './../../models/salesPerson';
import { SalesPersonService } from './../../services/sales-person.service';
import { NgForm } from '@angular/forms';
import {AuthenticationService} from './../../services/authentication.service';
import { SignInData } from './../../models/singInData';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //send login status to app component
  @Output() loginStatus:EventEmitter<boolean> = new EventEmitter();

  phone!: string;
  password!: string;
  encryptData!: string;
  // isLoggedIn!: boolean = true;
  salesPerson = new SalesPerson;
  isFormValid:boolean = true;
  areCredentialsInvalid = false;
  formSubmitted!:boolean;
  validMobileNo:boolean = true;

  constructor(
    private http: HttpClient,
    private salesPersonService: SalesPersonService,
    public authenticationService: AuthenticationService,
    public router: Router
  ) {}
  
  ngOnInit(): void {
  }

 
  onSubmit(signInForm: NgForm) {
    this.formSubmitted = true;
    const signInData = new SignInData(signInForm.value.phone, signInForm.value.password);
    console.log("What are submit ",signInData, this.phone);

    // Validate number again
    const phone = signInData.getMobile();
    if (isNaN(Number(phone)) || phone.length !== 10) {
      console.error("Invalid mobile number")
      return;
    }

    if (!signInForm.valid) {
      this.isFormValid = true;
      this.areCredentialsInvalid = false;
      return;
    }
    this.checkCredentials(signInForm);
    // this.loginUser(signInForm);
    // this.authenticationService.authenticate(signInData);

  }

  verifyMobileNo() {
    // check if the mobile number entered is valid
    let chkNum = /^\d+$/.test(this.phone);
    if (!chkNum || (this.phone.length !== 10)) {
      this.validMobileNo = false;
      return;
    } else {
      this.validMobileNo = true;
    }
  }
  
  private async checkCredentials(signInForm: NgForm) {
    // console.log("First authentic!!!")
    const signInData = new SignInData(signInForm.value.phone, signInForm.value.password);
    
    let authResponse = await this.authenticationService.authenticate(signInData);
    // console.log("Wha is ??",authResponse);
    if(authResponse){
      this.isFormValid = true;
      this.areCredentialsInvalid = false;
      this.loginStatus.emit(true);
    } else {
      this.isFormValid = false;
      this.loginStatus.emit(false);
    }
    
    // if (!this.authenticationService.authenticate(signInData)) {
    //   this.isFormValid = false;
    //   this.areCredentialsInvalid = true;
    // } else{
    //   // this.router.navigate(['add-user']);
    //   // return true;
    //   console.log("Is this printing!!! in success");

    //   //sending loginStatus to appComponent
    //   // this.loginStatus.emit(true);
    // }
  }

  // loginUser(signInForm: NgForm) {
  //   console.log("Login has been clicked!!!", signInForm.value.phone, signInForm.value.password);
  //   this.encryptData = CryptoJS.AES.encrypt(signInForm.value.password.trim(), signInForm.value.phone.trim()).toString();
  //   let url = "http://localhost:8082/api/login-user";
  //   this.http.post(url,
  //     {
  //       phone: signInForm.value.phone,
  //       password: this.encryptData,
  //       _method: "POST"
  //     }).toPromise().then((data: any) => {
  //       console.log("response from backend", data.data.userId);
  //     });
  // }

  // checkOperators(){
  //   // /api/get-sales-person
  //   let url = "http://localhost:8082/api/get-sales-person";
  //   this.http.post(url,{}).toPromise().then((data:any) => {
  //     // console.log("Operator List success", data);
  //   })
  // }

  


}
