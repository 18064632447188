import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule,routingComponents } from './../app-routing.module';
import { MaterialModule } from '../material/material.module';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AssignedLeadsComponent } from './assigned-leads/assigned-leads.component';
import { EditComponent } from './edit/edit.component';
import { ResetPasswordComponent } from './../shared/reset-password/reset-password.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { EditAppointmentComponent } from './edit-appointment/edit-appointment.component';
import { DirectionNavigateComponent } from './direction-navigate/direction-navigate.component';
import { SearchComponent } from './search/search.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from "ngx-spinner";
import { AgentComponent } from './agent/agent.component';


@NgModule({
  declarations: [routingComponents, AppointmentsComponent, AssignedLeadsComponent, EditComponent, CreateAppointmentComponent,
     EditAppointmentComponent, DirectionNavigateComponent, SearchComponent, AgentComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ScrollingModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey:"",
      libraries: ['places', 'drawing', 'geometry'],
    })
  ],
  exports: [
  ]
})
export class ControllerModule { }
