import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
declare var canvasResize: any;
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {

  geocoder: google.maps.Geocoder;

  latitude!: any;
  longitude!: any;
  zoom = 11;

  inchargeLatitude!: any;
  inchargeLongitude!: any;

  capDate: any;
  address: any = {};

  formattedAddress: any = "";

  areaPredictions: any;
  showPredictions: boolean = false;

  mobileNo!: any
  name: string;
  email: string  = "";
  // showSubStatus:boolean = false;
  status: string = "";
  category!: any;
  categories!: any;
  otp!: any;
  userData!: any;

  buildingImage!: any;
  buildingImageFormat!: any;
  isBuildingImageUploaded: boolean = false;
  signageImage!: any;
  signageImageFormat!: any;
  isSignageImageUploaded: boolean = false;

  imagesFolder!: any;

  formSubmitted: boolean = false;
  validMobileNo: boolean = true; // check if the mobile number entered is valid or not
  validName: boolean = true;
  validEmail: boolean = true;

  intervalId!: any;

  @ViewChild('search')
  public searchElementRef!: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private http: HttpClient, private userService: UserService, private router: Router, private shared: SharedService) {
    this.geocoder = new google.maps.Geocoder();
    this.imgChanged = this.imgChanged.bind(this);
    // this.imgChanged.prototype.callFUnc = this.imgChanged.prototype.callFUnc.bind(this);
  }

  ngOnInit(): void {
    this.getSalesPersonDetails();
    this.getCategories();

    this.setCurrentLocation(true);

    //send page Title to header component
    this.shared.sendAppTitle("Vendor");


  }


  onSubmit(addUserForm: NgForm) {

    this.formSubmitted = true;
    console.log("Form valid", addUserForm.valid);
    // console.log("Add User Data", addUserForm.value,addUserForm.value.hasOwnProperty('mobile') , addUserForm.value.mobile != "",addUserForm.value.mobile != undefined);
    if (addUserForm.valid) {

      if(addUserForm.value.mobileNo && !this.validMobileNo) {
        console.error("Invalid mobile number: ",this.mobileNo);
        alert("Please enter a valid mobile number");
        return;
      }
      if(addUserForm.value.name && !this.validName) {
        console.error("Invalid name: ",this.name);
        alert("Please enter a valid name");
        return;
      }
      if(addUserForm.value.email && !this.validEmail) {
        console.error('Invalid email : ',this.email);
        alert("Please enter a valid email");
        return;
      }

      let formData = addUserForm.value;
      let salesPersonDetails: any = this.getSalesPersonDetails();
      salesPersonDetails = JSON.parse(salesPersonDetails);

      if (this.inchargeLatitude && this.inchargeLongitude) {
        formData.inchargeLatitude = this.inchargeLatitude;
        formData.inchargeLongitude = this.inchargeLongitude;
      } else {
        alert("Please allow location access to submit");
        if (!this.intervalId) {
          this.callSetCurrentLocation();
        }
        return;
      }

    let promises = [];

    if (this.buildingImage && !this.isBuildingImageUploaded) {
      let salesAgentData:any = {};
      Object.assign(salesAgentData, salesPersonDetails);
      salesAgentData.mobile = this.mobileNo;
      salesAgentData.fileFormat = this.buildingImageFormat;
      salesAgentData.imageType = "building";
      promises.push(this.uploadImage(salesAgentData));
    }
    if (this.signageImage && !this.isSignageImageUploaded) {
      let salesAgentData:any = {};
      Object.assign(salesAgentData, salesPersonDetails);
      salesAgentData.mobile = this.mobileNo;
      salesAgentData.fileFormat = this.signageImageFormat;
      salesAgentData.imageType = "sign";
      promises.push(this.uploadImage(salesAgentData));
    }

      // addressObj.formattedAddress = formData.formattedAddress;

      //name as contactName to match the api request.body
      formData.contactName = formData.name;

      let categoriesObj = { "key": "", "value": "" };
      if (formData.categories) {
        // Ternary operator validation incase the categories object's key value fields are already set.
        categoriesObj.key = formData.categories.consumerCategoryId ? formData.categories.consumerCategoryId : formData.categories.key;
        categoriesObj.value = formData.categories.name ? formData.categories.name : formData.categories.value;
      } else {
        categoriesObj = { "key": "", "value": "" };
      }

      formData.categories = categoriesObj;
      console.log("what categories picking up", formData.categories);

      //address
      this.address.formattedAddress = this.formattedAddress;
      this.address.address = this.formattedAddress // this needs to be cross checked with CRM codes
      this.address.landmark = formData.landmark;
      this.address.latitude = this.latitude;
      this.address.longitude = this.longitude;
      formData.address = this.address;

      //sales person details
      formData.userId = salesPersonDetails.userId;
      formData.inchargeUserId = salesPersonDetails.userId;
      formData.inchargeName = salesPersonDetails.name;
      formData.inchargeMobileNumber = salesPersonDetails.mobile;


      //If INTERESTED assign leadStatus as ACQUISITION else FOLLOWUP
      if (formData.status == "INTERESTED") {
        formData.leadStatus = "ACQUISITION";
      } else if (formData.status == "NOTINTERESTED") {
        formData.leadStatus = "FOLLOW-UP";
      }

      //convert mobile number to string
      if (formData.mobileNo) {
        formData.mobileNo = formData.mobileNo.toString();
      }


      console.log("Now what is the userdata", formData);

      Promise.all(promises).then((values) => {

        formData.images = this.imagesFolder;

        this.userService.addUser(formData).subscribe((response) => {
          if(response.status === "Success") {
            console.log("What is the response", response);
            alert("User added successfully!!!");
            if (formData.status == "INTERESTED") {
              this.router.navigate(['acquisition']);
            } else {
              this.router.navigate(['follow-up']);
            }
          } else if (response.status === "Failure") {
            console.error("What is the error response", response);
            alert("Unable to add user!!!");
            return;
          } else {
            console.error("Invalid response", response);
            alert("Unable to add user!!!");
            return
          }

        });
      })
        .catch((errors) => {
          alert("Unable to upload images!");
          console.error(errors);
        });
    } else {
      alert("Please add required fields")
      // window.location.reload();
    }

  }

  onCancel() {
    // this.router.navigate(['assigned-leads']);
    window.location.reload();
  }

  imgChanged(event) {
    // Set image Format
    let file: File = event.target.files[0];
    const fileName = file.name;
    let extension = fileName.split('.').pop();
    if (event.target.id === "buildingImage") {
      this.buildingImageFormat = extension;
    } else if (event.target.id === "signageImage") {
      this.signageImageFormat = extension;
    }

    // Resize Image
    canvasResize(file, {
      width: 800,
      crop: false,
      quality: 80,
      callback: function (data, width, height) {

        if (event.target.id === "buildingImage") {
          (<HTMLImageElement>document.querySelector(".building")).src = data;
        } else if (event.target.id === "signageImage") {
          (<HTMLImageElement>document.querySelector(".sign")).src = data;
        } else {
          console.error("Invalid imageType");
          return;
        }
      }
    });
  }

  uploadImage(data) {

    return new Promise((resolve,reject) => {
      let select = data.imageType;

        fetch((<HTMLImageElement>document.querySelector("."+select)).src)
          .then((res) => {
            res.blob().then(blobFile => {

              this.userService.uploadImage(data, blobFile).subscribe((res: any) => {
                if (res.status == "Success") {
                  this.imagesFolder = res.data.images;
                  if (select === "building") {
                    this.isBuildingImageUploaded = true;
                  } else {
                    this.isSignageImageUploaded = true;
                  }
                  resolve(res.data.images);
                   console.log("Successfully uploaded the image!!");
                } else {
                  reject(res);
                  console.error(res);
                }
              });
            });
        })
        .catch((error) => {
          console.error("Unable to fetch image from "+ select +" element");
          reject(error);
        });

    });
  }

  getSalesPersonDetails() {
    // console.log("what are sales persondetails",localStorage.getItem("salesPerson"));
    return localStorage.getItem("salesPerson");
  };



  getCategories() {
    this.userService.getCategories(this.getSalesPersonDetails()).subscribe((res: any) => {
      if (res.status == "Success" && res.data.data.categories.length > 0) {
        this.categories = res.data.data.categories;
      }
    });
  }

  getLocationCheck() {
    let latLng = {
      lat: this.latitude,
      lng: this.longitude
    }
    this.geocoder.geocode({ location: latLng },
      ((results, status) => {
        this.formattedAddress = results[0].formatted_address;
        return this.formattedAddress;
      }))
  }

  // Get Current Location Coordinates
  private setCurrentLocation(setLatLng: any) {

    if ('geolocation' in navigator) {

      navigator.geolocation.getCurrentPosition((position) => {
        if (setLatLng) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 16;

          // To set formatted address from user's location
          this.getAddress(this.latitude, this.longitude);
        }

        this.inchargeLatitude = position.coords.latitude;
        this.inchargeLongitude = position.coords.longitude;

        // Clear interval if exists
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }, (error) => {
        console.log('error is: ',error);
        // alert('Please allow location access');
      });

    }
  }

  callSetCurrentLocation(): void {
    // For UX, call the method until location is picked
    let intervalId = setInterval(()=>{
      this.setCurrentLocation(false);
      // Clear interval when the screen changes
      if (!window.location.href.includes("add-user")) {
        clearInterval(this.intervalId);
      }
    },1000);
    this.intervalId = intervalId;
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    this.showPredictions = false;
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }


  getAddress(latitude: any, longitude: any) {
    this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.getAddressObj(results[0]);
          this.zoom = 16;
          this.formattedAddress = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  searchForLocation(data: any) {
    if (data) {
      this.getPredictions(data).subscribe((res: any) => {
        if (res.predictions.length > 0) {
          this.showPredictions = true;
          this.areaPredictions = res.predictions;

        }
        // this.predictions = res.predictions;
      });
    }
  }

  getPredictions(data: string) {
    let url = "https://mapproxy.click2clean.in/maps/api/place/autocomplete/json?input=" + data;
    return this.http.get(url, {})
  }

  locationSelected(obj: any) {
    this.showPredictions = false;
    this.formattedAddress = obj.description;
    this.getLatLng(obj.place_id).subscribe((response: any) => {
      let place = response.result;
      let obj!: any;
      // obj.lat = place.geometry.location.lat;
      // obj.lng = place.geometry.location.lng;
      this.latitude = place.geometry.location.lat;
      this.longitude = place.geometry.location.lng;
      this.zoom = 16;
      let adr = response.result.adr_address;


      // this.getAddress(this.latitude, this.longitude);

      this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
        console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.getAddressObj(results[0]);
            this.zoom = 16;
            // this.formattedAddress = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }

      });
    })
  }

  getLatLng(data: any) {
    let url = " https://mapproxy.click2clean.in/maps/api/place/details/json?placeid=" + data;
    return this.http.get(url, {});
  }


  getAddressObj(geoDetails: any) {
    let geometry = geoDetails.geometry;
    this.address.latitude = geometry.location.lat();
    this.address.longitude = geometry.location.lng();
    for (var i = geoDetails.address_components.length - 1; i >= 0; i--) {
      if (geoDetails.address_components[i].types[0] == "locality") {
        this.address.locality = geoDetails.address_components[i].long_name;
      }
      if (geoDetails.address_components[i].types[0] == "administrative_area_level_1") {
        this.address.state = geoDetails.address_components[i].long_name;
      }
      if (geoDetails.address_components[i].types[0] == "country") {
        this.address.country = geoDetails.address_components[i].long_name;
      }
      if (geoDetails.address_components[i].types[0] == "postal_code") {
        this.address.postalCode = geoDetails.address_components[i].long_name;
      }
    };

  }

  // check if the mobile number is already added either as Lead or already a registered Customer
  verifyMobileNo() {

    // check if the mobile number entered is valid
    let chkNum = /^\d+$/.test(this.mobileNo);
    if (!chkNum || (this.mobileNo.length < 10)) {
      this.validMobileNo = false;
      return;
    } else {
      this.validMobileNo = true;
    }

    this.checkMobile(this.mobileNo).subscribe((verifyStatus: any) => {
      // console.log("What is the verify status", verifyStatus,verifyStatus.data.length);
      if (verifyStatus.data.length) {
        alert("Lead already exists");
        this.mobileNo = "";
      } else {
        //check in ScrapQ db if the entered number is Already Registered Customer
        this.userService.verifyCustomerPreExists(this.getSalesPersonDetails(), this.mobileNo, "Hyderabad").subscribe((res: any) => {
          // console.log("What is res from verifyMobile",res);
          if (res.status == "Success" && res.data.data.users.length > 0) {
            alert("User already a registered customer");
            this.mobileNo = "";
          }
        })
      }
    })
  }

  verifyName() {
    // check if the name entered is valid
    let checkName = /^[A-Za-z .']+$/.test(this.name);
    if (this.name) {
      let replaceSpacesAndDotsAndCheckName = this.name.replace(/[\s.]+/g,"");
      if (!checkName || !replaceSpacesAndDotsAndCheckName) {
        this.validName = false;
        return;
      }
      this.validName = true;
    } else {
      this.validName = false;
    }
  }

  verifyEmail() {
    // check if the email entered is valid
    if(!this.email) {
      this.validEmail = false;
      return;
    }
    let checkEmail = /\S+@\S+\.\S+/.test(this.email);
    if (!checkEmail) {
      this.validEmail = false;
      return;
    } else {
      this.validEmail = true;
    }
  }

  checkMobile(mobileNo: any) {
    // let url = "http://localhost:8082/api/search/lead-web";
    let url = environment.apiUrl + "api/search/lead-web";

    return this.http.post(url, {
      mobileNo: mobileNo,
      _method: "POST"
    });
  }

  sendOtp() {
    console.log("Send otp");
    return;
  }

}



