<div class="main-div scrollId">
    <!-- <h3>Pricing Info</h3> -->
    <div class="form-group flex-col">
        <!-- <label for="address-type" for="">Address Type <span style="color:red">*</span></label> -->
        <select id="address-type" name="categories" [(ngModel)]="category" (change)="getPricingDetails(category.name)" autofocus>
            <!-- <option value="" default selected>Choose address type</option> -->
            <option *ngFor="let category of categories" [ngValue]="category">{{category.name}}</option>
        </select>
    </div>
    <table>
        <tr class="heading-tr">
            <th>Product</th>
            <th>Price</th>
            <th>UOM</th>
        </tr>
        <tr *ngFor="let pricing of pricingList">
            <td>{{pricing.name}}</td>
            <td>{{pricing.price}}</td>
            <td>{{pricing.uom}}</td>
        </tr>
    </table>
</div>