<div class="scrollId">

  <form action="" (ngSubmit)="onSubmit(createAppointmentForm)" #createAppointmentForm="ngForm">
    <div class="form1-align df">
      <div class="form-group flex-col">
        <label for="appointmentDate" for="">Date <span style="color:red">*</span></label>
        <button class="datepicker-btn" type="button" matSuffix (click)="picker.open()">
          <input matInput [matDatepicker]="picker" placeholder="Select a Date" style="width: 100%;"
            name="appointmentDate" [min]="todayDate" [formControl]="appointmentDate" required readonly>
          <mat-datepicker-toggle matSuffix [for]="picker" id="toggle" style="display: none;"></mat-datepicker-toggle>
          <mat-datepicker #picker color="primary"></mat-datepicker>
        </button>
      </div>
      <div class="form-group flex-col">
        <label for="appointmentTime" for="">Time </label>
        <input id="appointmentTime" name="appointmentTime" ngModel required autofocus type="time"
          style="width: 100% !important;">
      </div>
      <div class="form-group flex-col">
        <label for="appointmentDuration" for="">Duration </label>
        <select id="appointmentDuration" name="appointmentDuration" ngModel autofocus>
          <option value="" default selected>Select Duration</option>
          <option value="15 mins">15 mins</option>
          <option value="30 mins">30 mins</option>
          <option value="45 mins">45 mins</option>
          <option value="1 hr">1 hr</option>
        </select>
      </div>
      <div class="form-group flex-col">
        <label for="appointmentType" for="">Type <span style="color:red">*</span></label>
        <ul class="donate-now">
          <li style="width: 73px;">
            <input type="radio" name="appointmentType" id="call" ngModel value="Call"
              style="width: auto !important;"><label for="call" class="radio-btn-label">Call</label>
          </li>
          <li style="width: 73px;">
            <input type="radio" name="appointmentType" id="inperson" ngModel value="Inperson"><label for="inperson"
              class="radio-btn-label">Inperson</label>
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <div class="row btn-align">
      <button type="submit" class="save-btn">Save</button>
      <button class="cancel-btn" type="button" (click)="onCancel()">Cancel</button>
    </div>

  </form>
</div>
