import { Component ,OnInit} from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { SharedService } from './shared/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Sales-Web';
  loggedIn!: boolean;

  loginStatus = localStorage.getItem("salesPerson");



  constructor(private shared: SharedService, public authenticationService: AuthenticationService, public router: Router) {
    // console.log("Check login status in appcomponent",this.loggedIn,this.loginStatus);

    // if(this.loginStatus){
    //   this.loggedIn = true;
    // } else {
    //   this.loggedIn = false;
    // }

  }


  ngOnInit(): void {
    // Check for JWT when app is started
    let authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.authenticationService.authToken = authToken;
    }
    console.log("App component on Init!!!",this.authenticationService.isAuthenticated);
     if(this.loginStatus.length > 0){
      this.loggedIn = true;

      //make authentication true in Authentication service
      this.authenticationService.authenticateFromAppComponent();
    } else {
      this.loggedIn = false;
    }



  }




  checkLoginStatus(status) {
    if (status) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  toggleSideBar(): void {
    this.shared.toggleSideNav("toggleSideBar");
  }



}
