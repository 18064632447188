
<section class="section-design scrollId">
  <div class="row-design">
    <div class="df cards-containor">
      <div class="searchbox-containor">
        <input class="search-box" placeholder="Search lead by name or mobile" [(ngModel)]="search" name="search"
          (input)="changeUserType($event)" type="search">
      </div>

      <div class="col-sm-11">
        <div class="d-flex justify-content-center">
          <div class="form-group flex-col">

            <select name="usertype" id="usertype" class="formcontrol" [(ngModel)]='usertype'
              (change)="changeUserType($event)">

              <option value="Customer">Customer</option>
              <option value="Partner">Partner</option>
              <option value="Agent">REO</option>
              <option value="Vendor">Recycler</option>

            </select>
            <label for="usertype" for=""> &nbsp;&nbsp;&nbsp; <i class="fa fa-filter"></i> </label>
          </div>

        </div>
      </div>

      <div *ngFor="let assign of assignedArr" class="col-sm-5 col-design card-style"
        [ngClass]="(assignedArr.length%2===0)?'':'last-card-style'">
        <div class="row align-center">
          <div class="col-10 border-rt">
            <div class="content-left">
              <div class="content-details">
                <span class="icon-width"> <img class="img-icon-left" src="./../../../assets/images/receiverxxxhdpi.png"
                    alt=""></span>

                <div class="mobile-style">{{assign.mobileNo}}</div>
              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/userxxxhdpi.png"
                    alt=""></span>
                <div class="name-detail-align">
                  <div class="name-style">{{assign.name}}</div>
                  <div class="address-style">{{assign.address.formattedAddress}}</div>
                </div>

              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/homexxxhdpi.png"
                    alt=""></span>
                <div class="add-type-align"> <span class="add-type-style"> Address Type: </span> <span
                    class="val-typ-style"> {{assign.categories.value}} </span></div>
              </div>
            </div>

          </div>
          <div class="col-1 align-icons">
            <div class="content-right">
              <div (click)="openNavigation(assign)">
                <img class="img-style" src="./../../../assets/images/mapxxxhdpi.png" alt="">
              </div>
              <div (click)="openEditComponent(assign)">
                <img class="img-style" src="./../../../assets/images/editxxxhdpi.png" alt="">
              </div>
              <div (click)="openCreateAppointment(assign)">
                <img class="img-style" src="./../../../assets/images/appoinmentxxxhdpi.png" alt="">
              </div>
              <div (click)="unAssignLead(assign._id)">
                <img class="img-style" src="./../../../assets/images/unassign_redxxxhdpi.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="moip"></div>
</section>
