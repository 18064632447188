<section class="section-design scrollId">

  <div class="row-design" style="padding-left: 0;">

    <div class="df cards-containor" style="justify-content: center;">
      <div class="searchbox-containor">
        <input class="search-box" placeholder="Search lead by name or mobile" name="search" [(ngModel)]="search"
          (input)="changeUserType(null)" type="search">
      </div>
    </div>

    <div class="col-sm-11  mt-2 mb-4">
      <div class="d-flex justify-content-center">
        <div class="form-group flex-col">
          <select name="usertype" id="usertype" class=" formcontrol" [(ngModel)]='usertype'
            (change)="changeUserType($event)">
            <option value="Customer">Customer</option>
            <option value="Partner">Partner</option>
            <option value="Agent">REO</option>
            <option value="Vendor">Recycler</option>
          </select>
          <label for="usertype" for=""> &nbsp;&nbsp;&nbsp;<i class="fa fa-filter"></i> </label>
        </div>
      </div>
    </div>

    <div class="row df cards-containor" style="justify-content: center;">
      <div class="col-sm-5 col-design card-style" *ngFor="let leadItem of assignedArr"
        [ngClass]="(assignedArr.length%2===0)?'':'last-card-style'">
        <div class="row align-center" #triggerScroll>
          <div class="col-10 border-rt">

            <div class="content-left">
              <div class="content-details">
                <span class="icon-width"> <img class="img-icon-left" src="./../../../assets/images/receiverxxxhdpi.png"
                    alt=""></span>

                <div class="mobile-style">{{leadItem.mobileNo}}</div>
              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/userxxxhdpi.png"
                    alt=""></span>
                <div class="name-detail-align">
                  <div class="name-style">{{leadItem.name}}</div>
                  <div class="address-style">{{leadItem.address.address}}
                  </div>
                </div>

              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/homexxxhdpi.png"
                    alt=""></span>
                <div class="add-type-align"> <span class="add-type-style"> Address Type: </span>
                  <span class="val-typ-style"> {{leadItem.categories.value}}
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div class="col-1 align-icons">
            <div class="content-right">
              <div (click)="openEditComponent(leadItem)">
                <img class="img-style" src="./../../../assets/images/editxxxhdpi.png" alt="">
              </div>
              <div (click)="openNavigation(leadItem)">
                <img class="img-style" src="./../../../assets/images/mapxxxhdpi.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="moip"></div>
</section>
