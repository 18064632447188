import { Component, OnInit,Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {FormControl,ReactiveFormsModule} from '@angular/forms';


// To format the input display date in datepicker
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-edit-appointment',
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class EditAppointmentComponent implements OnInit {


  mobileNoToFetchLeadDetails!:any;
  id!:any;
  appointmentTime!:any;
  appointmentDate!:any;
  appointmentType!:any;
  appointmentDuration!:any;
  submitted:boolean = false;
  todayDate:any = new Date();


  constructor(private userService: UserService,private router:Router,private route:ActivatedRoute,private shared:SharedService) {
    this.mobileNoToFetchLeadDetails = this.route.snapshot.paramMap.get('mobileNo');
   }

   ngOnInit(): void {


    this.getDetailsToEdit(this.mobileNoToFetchLeadDetails);
      
    this.getSalesPersonDetails();

     //send page Title to header component
     this.shared.sendAppTitle("Edit Appointment");

     this.todayDate = moment(this.todayDate).format("YYYY-MM-DD");

  }

  getSalesPersonDetails() {
    return localStorage.getItem("salesPerson");
  };

   getDetailsToEdit(mobile:any){
    this.userService.searchUserForEdit(mobile).subscribe((response) => {
      if(response.status == "Success" && response.data.length > 0){
        let editData = response.data[0];
        console.log("What is the data getting",editData);
        this.id = editData._id;
        this.appointmentDate = new FormControl(new Date(editData.appointmentDate));
        this.appointmentDuration = editData.appointmentDuration;
        this.appointmentType = editData.appointmentType;
        editData.leadStatus = "APPOINTMENT";

        this.appointmentTime = editData.appointmentTime;
      }
    })
  }



  onSubmit(editAppointmentForm: NgForm) {
    this.submitted = true;
    let appointmentData = editAppointmentForm.value;
    // return;

    if(appointmentData.appointmentTime != ""){
      let timeString = appointmentData.appointmentTime.split(":");
      let appTime = new Date(1970,0,1, timeString[0], timeString[1]);
      // appointmentData.appointmentTime = moment.utc(appTime).format("YYYY-MM-DDTHH:mm:ss");
       appointmentData.appointmentTime = appTime;
    }
    
    if(appointmentData.appointmentDate != "" && appointmentData.appointmentType != "" && appointmentData.appointmentTime != ""){
      // appointmentData.
      appointmentData.id = this.id;

      //date shall be in UTC format
      let convertibleDate;
      try {
        // convertibleDate = new Date(this.appointmentDate.value.format("YYYY-MM-DD"));
        convertibleDate = moment(this.appointmentDate.value).format("YYYY-MM-DDTHH:mm:ss");
        console.log("what is convertible date",convertibleDate);
      } catch (e) {
        console.error(e);
        return;
      }
      // let selectedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
      // let selectedDateInUT = convertibleDate.toUTCString();
      // let selectedDateInUTC = moment.utc(selectedDateInUT).format("YYYY-MM-DDTHH:mm:ss");

      appointmentData.appointmentDate = convertibleDate;

       //sales person details
       let salesPersonDetails: any = this.getSalesPersonDetails();
      salesPersonDetails = JSON.parse(salesPersonDetails);
       appointmentData.userId = salesPersonDetails.userId;
       appointmentData.inchargeUserId = salesPersonDetails.userId;
       appointmentData.inchargeName = salesPersonDetails.name;
       appointmentData.inchargeMobileNumber = salesPersonDetails.mobile;
      appointmentData.leadStatus = "APPOINTMENT"; //default: converting any Lead to APPOINTMENT while creating appointment
      // console.log("data from create appointment",appointmentData);
      this.userService.createAppointment(appointmentData).subscribe((response) => {
        // console.log("What is the response of createAppointment",response);
        if(response.status == "Success"){
          alert("Appointment Created Successfully");
          this.router.navigate(['appointments']);
        } else{
          alert("Update Appointment Failed");
          this.router.navigate(['assigned-leads']);
        }
      })
    }
  }

  onCancel(){
    this.router.navigate(['appointments']);
  }

  

}
