<section class="section-design scrollId">

  <div class="row-design">
    <div class="df cards-containor">
      <div class="searchbox-containor">
        <input class="search-box" autocomplete="off" placeholder="Search lead by name or mobile" [(ngModel)]="search"
          name="search" (input)="getUserDetails()" type="search">
      </div>
      <div *ngFor="let user of userDataArr" class="col-sm-5 col-design card-style"
        [ngClass]="(userDataArr.length%2===0)?'':'last-card-style'">
        <div class="row align-center">
          <div class="col-10">
            <div class="content-left">
              <div class="content-details">
                <span class="icon-width"> <img class="img-icon-left" src="./../../../assets/images/receiverxxxhdpi.png"
                    alt=""></span>

                <div class="mobile-style">{{user.mobileNo}}</div>
              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/userxxxhdpi.png"
                    alt=""></span>
                <div class="name-detail-align">
                  <div class="name-style">{{user.name}}</div>
                  <div class="address-style">{{user.address.formattedAddress}}</div>
                </div>

              </div>
              <div class="content-details">
                <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/clockxxxhdpi.png"
                    alt=""></span>
                <div class="add-type-align"> <span class="add-type-style"> Status: </span> <span class="val-typ-style">
                    {{user.leadStatus}} </span></div>
              </div>
            </div>

          </div>
          <div class="col-1 align-icons">
            <div class="content-right">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
