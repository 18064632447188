import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
declare var canvasResize: any;

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  isBuilding: boolean = false;
  isSign: any;
  isPan: any;
  isGst: any;
  isPrice: any;
  isOther: any;
  noCameraExist: boolean = false;

  geocoder: google.maps.Geocoder;

  latitude!: any;
  longitude!: any;
  zoom = 11;

  inchargeLatitude!: any;
  inchargeLongitude!: any;

  capDate: any;
  address: any = {};

  formattedAddress: any = '';

  areaPredictions: any;
  showPredictions: boolean = false;

  mobileNo!: any;
  landmark!: any;
  pannumber!: any;
  gstnumber!: any;
  upinumber!: any;
  aadhar!: any;
  accountNumber!: any;
  ifsc!: any;
  name: string;
  email: string = '';
  // showSubStatus:boolean = false;
  status: string = '';
  venderType: string = '';
  category!: any;
  categories!: any;
  editData!: any;
  editCategory!: any;
  productCategories!: any;
  usertype!: any;
  otp!: any;
  userData!: any;
  comment!: any;
  comments!: any;
  frequency!: any;
  editFrequency!: any;
  editSubStatus!: any;
  leadStatus!: any;

  buildingImage!: any;
  buildingImageFormat!: any;
  isBuildingImageUploaded: boolean = false;
  signageImage!: any;
  signageImageFormat!: any;
  isSignageImageUploaded: boolean = false;
  pancardImage!: any;
  pancardImageFormat!: any;
  isPancardImageUploaded: boolean = false;

  gstImage!: any;
  gstImageFormatt!: any;
  isGSTImageUploaded: boolean = false;

  priceImage!: any;
  priceImageFormat!: any;
  isPriceImageUploaded: boolean = false;

  otherImage!: any;
  otherImageFormat!: any;
  isotherImageUploaded: boolean = false;

  imagesFolder: Array<String> = [];

  showImages: boolean = false;
  imagesCount: number = 0;
  imagesArray!: any;

  formSubmitted: boolean = false;
  validMobileNo: boolean = true; // check if the mobile number entered is valid or not
  validName: boolean = true;
  validEmail: boolean = true;

  intervalId!: any;

  building!: any;
  sign!: any;
  pan!: any;
  gst!: any;
  price!: any;
  other!: any;

  buildingPdf!: any;
  signPdf!: any;
  panPdf!: any;
  gstPdf!: any;
  pricePdf!: any;
  otherPdf!: any;

  @ViewChild('search')
  public searchElementRef!: ElementRef;

  showWebcam = true;
  isCameraExist = true;
  mobileNoToFetchLeadDetails!: any;
  id!: any;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  constructor(
    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private shared: SharedService,
    private _location: Location
  ) {
    this.geocoder = new google.maps.Geocoder();
    this.imgChanged = this.imgChanged.bind(this);
    this.mobileNoToFetchLeadDetails =
      this.route.snapshot.paramMap.get('mobileNo');
    this.id = this.route.snapshot.paramMap.get('id');
    // this.imgChanged.prototype.callFUnc = this.imgChanged.prototype.callFUnc.bind(this);
  }

  ngOnInit(): void {
    this.otherImage = '';
    this.buildingImage = '';
    this.signageImage = '';
    this.priceImage = '';
    this.gstImage = '';
    this.pancardImage = '';

    this.imagesArray = [];
    this.categories = [];

    this.getCategories();
    this.getSalesPersonDetails();
    this.setCurrentLocation(false);
    this.getDetailsToEdit(this.mobileNoToFetchLeadDetails);

    //send page Title to header component
    this.shared.sendAppTitle('Edit User');
  }

  getSalesPersonDetails() {
    return localStorage.getItem('salesPerson');
  }

  getCategories() {
    this.userService
      .getCategories(this.getSalesPersonDetails())
      .subscribe((res: any) => {
        if (res.status == 'Success' && res.data.data.categories.length > 0) {
          let _categories: any = [];
          res.data.data.categories.forEach((x: any, i: number) => {
            x.isSelected = false;
            _categories.push(x);
            if (this.editData && this.editData.categories) {
              if (x.consumerCategoryId == this.editData.categories.key) {
                this.editCategory = x;
              }
            }
          });
          this.categories = _categories;
        }
      });
  }
  getLocationCheck() {
    let latLng = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.geocoder.geocode({ location: latLng }, (results, status) => {
      this.formattedAddress = results[0].formatted_address;
      return this.formattedAddress;
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation(setLatLng: any) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (setLatLng) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.zoom = 16;

            // To set formatted address from user's location
            this.getAddress(this.latitude, this.longitude);
          }

          this.inchargeLatitude = position.coords.latitude;
          this.inchargeLongitude = position.coords.longitude;

          // Clear interval if exists
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
        },
        (error) => {
          console.log('error is: ', error);
          // alert('Please allow location access');
        }
      );
    }
  }

  callSetCurrentLocation(): void {
    // For UX, call the method until location is picked
    let intervalId = setInterval(() => {
      this.setCurrentLocation(false);
      // Clear interval when the screen changes
      if (!window.location.href.includes('edit')) {
        clearInterval(this.intervalId);
      }
    }, 1000);
    this.intervalId = intervalId;
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    this.showPredictions = false;
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude: any, longitude: any) {
    this.geocoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.getAddressObj(results[0]);
            this.zoom = 12;
            this.formattedAddress = results[0].formatted_address;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  searchForLocation(data: any) {
    if (data) {
      this.getPredictions(data).subscribe((res: any) => {
        if (res.predictions.length > 0) {
          this.showPredictions = true;
          this.areaPredictions = res.predictions;
        }
        // this.predictions = res.predictions;
      });
    }
  }

  getPredictions(data: string) {
    let url =
      'https://mapproxy.click2clean.in/maps/api/place/autocomplete/json?input=' +
      data;
    return this.http.get(url, {});
  }

  locationSelected(obj: any) {
    this.showPredictions = false;
    this.formattedAddress = obj.description;
    this.getLatLng(obj.place_id).subscribe((response: any) => {
      let place = response.result;
      let obj!: any;
      this.latitude = place.geometry.location.lat;
      this.longitude = place.geometry.location.lng;
      this.zoom = 16;
      let adr = response.result.adr_address;

      // this.getAddress(this.latitude, this.longitude);

      this.geocoder.geocode(
        { location: { lat: this.latitude, lng: this.longitude } },
        (results, status) => {
          console.log(status);
          if (status === 'OK') {
            if (results[0]) {
              this.getAddressObj(results[0]);
              this.zoom = 16;
              // this.formattedAddress = results[0].formatted_address;
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        }
      );
    });
  }

  getLatLng(data: any) {
    let url =
      ' https://mapproxy.click2clean.in/maps/api/place/details/json?placeid=' +
      data;
    return this.http.get(url, {});
  }

  getAddressObj(geoDetails: any) {
    let geometry = geoDetails.geometry;
    this.address.latitude = geometry.location.lat();
    this.address.longitude = geometry.location.lng();
    for (var i = geoDetails.address_components.length - 1; i >= 0; i--) {
      if (geoDetails.address_components[i].types[0] == 'locality') {
        this.address.locality = geoDetails.address_components[i].long_name;
      }
      if (
        geoDetails.address_components[i].types[0] ==
        'administrative_area_level_1'
      ) {
        this.address.state = geoDetails.address_components[i].long_name;
      }
      if (geoDetails.address_components[i].types[0] == 'country') {
        this.address.country = geoDetails.address_components[i].long_name;
      }
      if (geoDetails.address_components[i].types[0] == 'postal_code') {
        this.address.postalCode = geoDetails.address_components[i].long_name;
      }
    }
  }

  // check if the mobile number is already added either as Lead or already a registered Customer
  verifyMobileNo() {
    // check if the mobile number entered is valid
    let chkNum = /^\d+$/.test(this.mobileNo);
    if (!chkNum || this.mobileNo.length < 10) {
      this.validMobileNo = false;
      return;
    } else {
      this.validMobileNo = true;
    }

    this.checkMobile(this.mobileNo).subscribe((verifyStatus: any) => {
      // console.log("What is the verify status", verifyStatus,verifyStatus.data.length);
      if (verifyStatus.data.length) {
        alert('Lead already exists');
        this.mobileNo = '';
      } else {
        //check in ScrapQ db if the entered number is Already Registered Customer
        this.userService
          .verifyCustomerPreExists(
            this.getSalesPersonDetails(),
            this.mobileNo,
            'Hyderabad'
          )
          .subscribe((res: any) => {
            // console.log("What is res from verifyMobile",res);
            if (res.status == 'Success' && res.data.data.users.length > 0) {
              alert('User already a registered customer');
              this.mobileNo = '';
            }
          });
      }
    });
  }
  verifyName() {
    // check if the name entered is valid
    let checkName = /^[A-Za-z .']+$/.test(this.name);
    if (this.name) {
      let replaceSpacesAndDotsAndCheckName = this.name.replace(/[\s.]+/g, '');
      if (!checkName || !replaceSpacesAndDotsAndCheckName) {
        this.validName = false;
        return;
      }
      this.validName = true;
    } else {
      this.validName = false;
    }
  }

  verifyEmail() {
    // check if the email entered is valid
    if (!this.email) {
      this.validEmail = false;
      return;
    }
    let checkEmail = /\S+@\S+\.\S+/.test(this.email);
    if (!checkEmail) {
      this.validEmail = false;
      return;
    } else {
      this.validEmail = true;
    }
  }

  checkMobile(mobileNo: any) {
    // let url = "http://localhost:8082/api/search/lead-web";
    let url = environment.apiUrl + 'api/search/lead-web';

    return this.http.post(url, {
      mobileNo: mobileNo,
      _method: 'POST',
    });
  }

  openLink(link) {
    window.open(link, '_blank');
  }

  getDetailsToEdit(mobile: any) {
    this.userService.searchUserForEdit(mobile).subscribe((response) => {
      if (response.status == 'Success' && response.data.length > 0) {
        let editData = response.data[0];
        this.editData = editData;
        console.log('What is editData', editData);
        this.name = editData.name;
        this.usertype = editData.userType;
        this.mobileNo = editData.mobileNo;
        this.email = editData.email;
        this.pannumber = editData.panNumber;
        this.gstnumber = editData.gstNumber;
        this.accountNumber = editData.accountNumber;
        this.ifsc = editData.ifsc;
        this.aadhar = editData.aadhar;
        this.address = editData.address;
        this.editFrequency = editData.frequency;
        this.editSubStatus = editData.subStatus;
        this.upinumber = editData.upiID;
        this.comments = editData.comments;
        this.comment =
          editData.comments && editData.comments.length > 0
            ? editData.comments[0].comment
            : '';

        if (editData.categories) {
          this.categories.forEach((x: any, i: number) => {
            if (x.consumerCategoryId == editData.categories.key) {
              this.editCategory = x;
            }
          });
        }

        if (editData.images) {
          editData.images.forEach((image) => {
            if (image.includes('building')) {
              this.building = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.building')).onclick =
                () => this.openLink(image);
            } else if (image.includes('sign')) {
              this.sign = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.sign')).onclick =
                () => this.openLink(image);
            } else if (image.includes('pan')) {
              this.pan = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.pan')).onclick = () =>
                this.openLink(image);
            } else if (image.includes('gst')) {
              this.gst = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.gst')).onclick = () =>
                this.openLink(image);
            } else if (image.includes('price')) {
              this.price = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.price')).onclick =
                () => this.openLink(image);
            } else if (image.includes('other')) {
              this.other = image.includes('pdf')
                ? './../../../assets/images/pdf.jpeg'
                : environment.API_URL + image;
              (<HTMLImageElement>document.querySelector('.other')).onclick =
                () => this.openLink(image);
            } else {
            }
          });
        }

        this.formattedAddress = editData.address.formattedAddress;
        this.landmark = editData.address.landmark;
        this.address = editData.address;
        this.latitude = editData.address.latitude;
        this.longitude = editData.address.longitude;
        if (editData.status) {
          this.status = editData.status;
        }
        if (editData.subStatus) {
          //this.subStatus = editData.subStatus;
        }
        this.mobileNo = editData.mobileNo;
        this.otp = editData.otp ? editData.otp : '';
        this.email = editData.email ? editData.email : '';
        this.id = editData._id;
        this.leadStatus = editData.leadStatus;
      }
    });
  }

  onSubmit(addUserForm: NgForm) {
    console.log(addUserForm);
    this.formSubmitted = true;
    console.log('Form valid', addUserForm.valid);
    if (addUserForm.valid) {
      if (addUserForm.value.mobileNo && !this.validMobileNo) {
        console.error('Invalid mobile number: ', this.mobileNo);
        alert('Please enter a valid mobile number');
        return;
      }
      if (addUserForm.value.name && !this.validName) {
        console.error('Invalid name: ', this.name);
        alert('Please enter a valid name');
        return;
      }
      if (addUserForm.value.email && !this.validEmail) {
        console.error('Invalid email : ', this.email);
        alert('Please enter a valid email');
        return;
      }

      let formData = addUserForm.value;
      let salesPersonDetails: any = this.getSalesPersonDetails();
      salesPersonDetails = JSON.parse(salesPersonDetails);
      formData.id = this.id;
      if (this.inchargeLatitude && this.inchargeLongitude) {
        formData.inchargeLatitude = this.inchargeLatitude;
        formData.inchargeLongitude = this.inchargeLongitude;
      } else {
        formData.inchargeLatitude = '16.515072';
        formData.inchargeLongitude = '80.6387712';
      }

      let promises: any = [];

      if (this.buildingImageFormat && !this.isBuildingImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.buildingImageFormat;
        salesAgentData.imageType = 'building';
        promises.push(this.uploadImage(salesAgentData));
      }
      if (this.signageImageFormat && !this.isSignageImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.signageImageFormat;
        salesAgentData.imageType = 'sign';
        promises.push(this.uploadImage(salesAgentData));
      }

      if (this.priceImageFormat && !this.isPriceImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.priceImageFormat;
        salesAgentData.imageType = 'price';
        promises.push(this.uploadImage(salesAgentData));
      }

      if (this.gstImageFormatt && !this.isGSTImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.gstImageFormatt;
        salesAgentData.imageType = 'gst';
        promises.push(this.uploadImage(salesAgentData));
      }

      if (this.pancardImageFormat && !this.isPancardImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.pancardImageFormat;
        salesAgentData.imageType = 'pan';
        promises.push(this.uploadImage(salesAgentData));
      }

      if (this.otherImageFormat && !this.isotherImageUploaded) {
        let salesAgentData: any = {};
        Object.assign(salesAgentData, salesPersonDetails);
        salesAgentData.mobile = this.mobileNo;
        salesAgentData.fileFormat = this.otherImageFormat;
        salesAgentData.imageType = 'other';
        promises.push(this.uploadImage(salesAgentData));
      }

      //name as contactName to match the api request.body
      formData.contactName = formData.name;

      let categoriesObj = { key: '', value: '' };
      if (formData.categories) {
        // Ternary operator validation incase the categories object's key value fields are already set.
        categoriesObj.key = formData.categories.consumerCategoryId
          ? formData.categories.consumerCategoryId
          : formData.categories.key;
        categoriesObj.value = formData.categories.name
          ? formData.categories.name
          : formData.categories.value;
      } else {
        categoriesObj = { key: '', value: '' };
      }

      formData.categories = categoriesObj;
      console.log('what categories picking up', formData.categories);

      //address
      this.address.formattedAddress = this.formattedAddress;
      this.address.address = this.formattedAddress; // this needs to be cross checked with CRM codes
      this.address.landmark = formData.landmark;
      this.address.longitude = this.longitude;
      this.address.pannumber = this.pannumber;
      this.address.gstnumber = this.gstnumber;
      this.address.upinumber = this.upinumber;
      this.address.aadhar = this.aadhar;
      this.address.aadhar = this.aadhar;
      this.address.usertype = this.usertype;
      this.address.ifsc = this.ifsc;
      this.address.longitude = this.longitude;
      formData.address = this.address;

      //sales person details
      formData.userId = salesPersonDetails.userId;
      formData.inchargeUserId = salesPersonDetails.userId;
      formData.inchargeName = salesPersonDetails.name;
      formData.inchargeMobileNumber = salesPersonDetails.mobile;

      formData.userType = addUserForm.value.usertype;
      formData.flatNo = this.landmark;
      formData.gstNumber = this.gstnumber;
      formData.upiID = this.upinumber;
      formData.aadhar = this.aadhar;
      formData.accountNumber = this.accountNumber;
      formData.ifsc = this.ifsc;
      formData.panNumber = this.pannumber;

      //If INTERESTED assign leadStatus as ACQUISITION else FOLLOWUP
      if (formData.status == 'INTERESTED') {
        formData.leadStatus = 'ACQUISITION';
      } else if (formData.status == 'NOTINTERESTED') {
        formData.leadStatus = 'FOLLOW-UP';
      }

      formData.comment = (formData && this.comments && this.comments.length > 0 && formData.comment===this.comments[0].comment) ? null : formData.comment;
      //convert mobile number to string
      if (formData.mobileNo) {
        formData.mobileNo = formData.mobileNo.toString();
      }

      console.log('Now what is the userdata', formData);

      Promise.all(promises)
        .then((values) => {
          formData.images = this.imagesFolder;
          console.log('==============Add User API Call===================');
          console.log(formData);
          //return;

          this.userService.editUser(formData).subscribe((response) => {
            if (response.status === 'Success') {
              console.log('What is the response', response);
              alert('User data updated successfully!!!');
              if (formData.status == 'INTERESTED') {
                this.router.navigate(['acquisition']);
              } else {
                this.router.navigate(['follow-up']);
              }
            } else if (response.status === 'Failure') {
              console.error('What is the error response', response);
              alert('Unable to add user!!!');
              return;
            } else {
              console.error('Invalid response', response);
              alert('Unable to add user!!!');
              return;
            }
          });
        })
        .catch((errors) => {
          alert('Unable to upload images!');
          console.error(errors);
        });
    } else {
      alert('Please add required fields');
      // window.location.reload();
    }
  }

  onCancel() {
    this._location.back();
  }

  imgChanged(event) {
    // Set image Format
    let file: File = event.target.files[0];

    console.log(
      '====event.target.id=' + event.target.id + '==filename==' + file.name
    );
    const fileName = file.name;
    let extension = fileName.split('.').pop();
    if (event.target.id === 'buildingImage') {
      this.buildingImageFormat = extension;
    } else if (event.target.id === 'signageImage') {
      this.signageImageFormat = extension;
    } else if (event.target.id === 'pancardImage') {
      this.pancardImageFormat = extension;
    } else if (event.target.id === 'gstImage') {
      this.gstImageFormatt = extension;
    } else if (event.target.id === 'priceImage') {
      this.priceImageFormat = extension;
    } else if (event.target.id === 'otherImage') {
      this.otherImageFormat = extension;
    }
    var imgpath = './../../../assets/images/tick.jpeg';
    var pdfpath = './../../../assets/images/pdf.jpeg';
    if (event.target.id === 'buildingImage') {
      this.isBuilding = true;
      this.buildingImage = file.name;
      (<HTMLImageElement>document.querySelector('.building')).src = imgpath;
    } else if (event.target.id === 'signageImage') {
      this.isSign = true;
      this.signageImage = file.name;
      (<HTMLImageElement>document.querySelector('.sign')).src = imgpath;
    } else if (event.target.id === 'pancardImage') {
      this.isPan = true;
      this.pancardImage = file.name;
      (<HTMLImageElement>document.querySelector('.pan')).src = imgpath;
    } else if (event.target.id === 'priceImage') {
      this.isPrice = true;
      this.priceImage = file.name;
      (<HTMLImageElement>document.querySelector('.price')).src = imgpath;
    } else if (event.target.id === 'gstImage') {
      this.isGst = true;
      this.gstImage = file.name;
      (<HTMLImageElement>document.querySelector('.gst')).src = imgpath;
    } else if (event.target.id === 'otherImage') {
      this.isOther = true;
      this.otherImage = file.name;
      (<HTMLImageElement>document.querySelector('.other')).src = imgpath;
    }

    if (extension === 'pdf') {
      const reader = new FileReader();
      reader.onload = function (eve) {
        const arrayBuffer = eve.target.result;
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        console.log('event.target.id -------------------: ', event.target.id);
        if (event.target.id === 'buildingImage') {
          this.buildingPdf = blob;
        } else if (event.target.id === 'signageImage') {
          this.signPdf = blob;
        } else if (event.target.id === 'pancardImage') {
          this.panPdf = blob;
        } else if (event.target.id === 'priceImage') {
          this.pricePdf = blob;
        } else if (event.target.id === 'gstImage') {
          this.gstPdf = blob;
        } else if (event.target.id === 'otherImage') {
          this.otherPdf = blob;
        }
      }.bind(this);
      reader.readAsArrayBuffer(file); // Reading the file as an array buffer
      return;
    }

    // Resize Image
    canvasResize(file, {
      width: 800,
      crop: false,
      quality: 80,

      callback: (data, width, height) => {
        console.log('-==========imgpath=====================');
        this.showImages = true;
        this.imagesCount = this.imagesCount + 1;
        this.imagesArray.push(data);
        if (event.target.id === 'buildingImage') {
          this.isBuilding = true;
          this.buildingImage = file.name;
          (<HTMLImageElement>document.querySelector('.building')).src = data;
        } else if (event.target.id === 'signageImage') {
          this.isSign = true;
          this.signageImage = file.name;
          (<HTMLImageElement>document.querySelector('.sign')).src = data;
        } else if (event.target.id === 'pancardImage') {
          this.isPan = true;
          this.pancardImage = file.name;
          (<HTMLImageElement>document.querySelector('.pan')).src = data;
        } else if (event.target.id === 'priceImage') {
          this.isPrice = true;
          this.priceImage = file.name;
          (<HTMLImageElement>document.querySelector('.price')).src = data;
        } else if (event.target.id === 'gstImage') {
          this.isGst = true;
          this.gstImage = file.name;
          (<HTMLImageElement>document.querySelector('.gst')).src = data;
        } else if (event.target.id === 'otherImage') {
          this.isOther = true;
          this.otherImage = file.name;
          (<HTMLImageElement>document.querySelector('.other')).src = data;
        } else {
          console.error('Invalid imageType');
          return;
        }
      },
    });
  }

  uploadImage(data) {
    console.log('=====uploadImage========' + JSON.stringify(data));
    return new Promise((resolve, reject) => {
      let select = data.imageType;
      console.log(data);
      if (data.fileFormat == 'pdf') {
        console.log('Uploading pdf---------------: ', select + 'Pdf');
        this.userService
          .uploadImage(data, this[select + 'Pdf'])
          .subscribe((res: any) => {
            console.log('=====Success========' + JSON.stringify(data));
            if (res.status == 'Success') {
              this.imagesFolder = res.data.images;
              if (select === 'building') {
                this.isBuildingImageUploaded = true;
              } else if (select === 'sign') {
                this.isSignageImageUploaded = true;
              } else if (select === 'pan') {
                this.isPancardImageUploaded = true;
              } else if (select === 'price') {
                this.isPriceImageUploaded = true;
              } else if (select === 'gst') {
                this.isGSTImageUploaded = true;
              } else if (select === 'other') {
                this.isotherImageUploaded = true;
              }
              resolve(res.data.images);
              console.log('Successfully uploaded the image!!');
            } else {
              reject(res);
              console.error(res);
            }
          });
      } else {
        fetch((<HTMLImageElement>document.querySelector('.' + select)).src)
          .then((res) => {
            res.blob().then((blobFile) => {
              console.log('Uploading img---------------: ', select);
              this.userService
                .uploadImage(data, blobFile)
                .subscribe((res: any) => {
                  console.log('=====Success========' + JSON.stringify(data));
                  if (res.status == 'Success') {
                    this.imagesFolder = res.data.images;
                    if (select === 'building') {
                      this.isBuildingImageUploaded = true;
                    } else if (select === 'sign') {
                      this.isSignageImageUploaded = true;
                    } else if (select === 'pan') {
                      this.isPancardImageUploaded = true;
                    } else if (select === 'price') {
                      this.isPriceImageUploaded = true;
                    } else if (select === 'gst') {
                      this.isGSTImageUploaded = true;
                    } else if (select === 'other') {
                      this.isotherImageUploaded = true;
                    }
                    resolve(res.data.images);
                    console.log('Successfully uploaded the image!!');
                  } else {
                    reject(res);
                    console.error(res);
                  }
                });
            });
          })
          .catch((error) => {
            console.error('Unable to fetch image from ' + select + ' element');
            reject(error);
          });
      }
    });
  }

  public onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      console.log('Upload image file name =======');
      console.log(file.name);
    } else {
      console.log('Upload image failed');
    }
  }

  public arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }
}
