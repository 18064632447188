import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesPersonService {

  constructor() { };

  isUserLoggedIn!:boolean;

  authenticateUser(logIn:boolean){
    this.isUserLoggedIn = logIn;
  }

  getUserLogInStatus(){
    return this.isUserLoggedIn;
  }
}
