<section class="section-design scrollId">

  <div class="calendar-style">
    <div class="display-calendar">
      <div class="month-name">
        <span (click)="previousMonth()" style="cursor: pointer;">
          < </span> {{monthString}} <span (click)="nextMonth()" style="cursor: pointer;"> > </span>
      </div>
      <div class="scroll-calendar">
        <button class="scroll-btn-left" (click)="scrollCalendarLeft()">
          < </button>
            <div class="display-scroll" #viewDate>
              <div class="display-date" *ngFor="let day of days;let idx = index" #scrollDiv>
                <label class="cursor-pointer" (click)="getAppointmentLeadsFromService(day)">
                  <div for="capDateId">{{day | date:'EEE'}}</div>
                  <input type="radio" class="radio-display-none capDateId" name="capDate" [ngModel]="modelDate"
                    [value]="day">
                  <div class="radio-date-style">{{day | date:'dd'}}</div>
                </label>
              </div>
            </div>
            <button id="scroll-button" class="scroll-btn-left" (click)="scrollCalendarRight()">
              >
            </button>
      </div>
    </div>
  </div>

  <div class="row-design">
    <div class="df  cards-containor" *ngIf="!noAppointments">
      <div class="searchbox-containor">
        <input class="search-box" autocomplete="off" placeholder="Search lead by name or mobile" [(ngModel)]="search"
          name="search" (keyup)="searchLeads()" type="search">
      </div>
      <ngx-spinner type="ball-beat" size="small"></ngx-spinner>
      <div *ngFor="let appointments of appointmentsArr" class="col-sm-5 col-design card-style"
        [ngClass]="(appointmentsArr.length%2===0)?'':'last-card-style'">
        <div class="row align-center">
          <div class="col-10 border-rt" style="overflow: hidden; text-overflow: ellipsis;">
            <div class="row">
              <div class="col-9">
                <div class="content-left">
                  <div class="content-details">
                    <span class="icon-width"> <img class="img-icon-left"
                        src="./../../../assets/images/nav-03xxxhdpi.png" alt=""></span>

                    <div class="date-style">
                      <span style="font-weight: 300;">{{appointments.appointmentDate | date:'dd
                        MMM
                        YYYY' }}</span>&nbsp;
                      <span>{{appointments.appointmentTime | date:'shortTime'}}</span>
                    </div>
                  </div>
                  <div class="content-details">
                    <span class="icon-width"> <img class="img-icon-left"
                        src="./../../../assets/images/receiverxxxhdpi.png" alt=""></span>

                    <div class="mobile-style">{{appointments.mobileNo}}</div>
                  </div>
                  <div class="content-details">
                    <span class="icon-width"><img class="img-icon-left" src="./../../../assets/images/userxxxhdpi.png"
                        alt=""></span>
                    <div class="name-detail-align">
                      <div class="name-style">{{appointments.name}}</div>
                      <p class="address-style">{{appointments.address.formattedAddress}}</p>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="content-middle" *ngIf="appointments.appointmentType =='Call'">
                  <div class="content-mid-align">
                    <img class="appointment-status-icon" src="./../../../assets/images/call_smallxxxhdpi.png" alt="">
                  </div>
                  <div class="appointment-status-text">
                    Call
                  </div>
                </div>
                <div class="content-middle" *ngIf="appointments.appointmentType =='Inperson'">
                  <div class="content-mid-align">
                    <img class="appointment-status-icon" src="./../../../assets/images/inperson_smallxxxhdpi.png"
                      alt="">
                  </div>
                  <div class="appointment-status-text">
                    In Person
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-1 align-icons">
            <div class="content-right">
              <div (click)="openNavigation(appointments)">
                <img class="img-style" src="./../../../assets/images/mapxxxhdpi.png" alt="">
              </div>
              <div (click)="openEditComponent(appointments)">
                <img class="img-style" src="./../../../assets/images/editxxxhdpi.png" alt="">
              </div>
              <div (click)="openEditAppointment(appointments)">
                <img class="img-style" src="./../../../assets/images/appoinmentxxxhdpi.png" alt="">
              </div>
              <div (click)="unAssignLead(appointments._id)">
                <img class="img-style" src="./../../../assets/images/unassign_redxxxhdpi.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noAppointments" class="no-data">
      <img class="no-data-img" src="./../../../assets/images/unassign_red.svg" alt="">
      <p>
        No Appointments
      </p>
    </div>
  </div>
</section>
