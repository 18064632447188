    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
    <mat-toolbar class="navbar">
    <div class="flex-apply">
        <div>
          <img class="logo" src="./../../../assets/images/mainlogo.png" alt="" style="padding:20px">
            <img class="menu-style" (click)="toggleSideBar()" src="./../../../assets/images/hamburgerxxxhdpi.png"
                alt="">
        </div>
        <div class="headerTitle">{{activeHeader}}</div>
    </div>
    <div class="flex-apply" (click)="logout()">
        <span class="user-align">{{name}}</span>
        <span class="avatar-align"><img src="./../../../assets/images/avatar.svg" alt=""></span>
    </div>
</mat-toolbar>
