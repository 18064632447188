export class SignInData {
    private mobile: string;
    private password: string;

    constructor(mobile: string, password: string) {
        this.mobile = mobile;
        this.password = password;
    }

    getMobile(): string {
        return this.mobile;
    }

    getPassword(): string {
        return this.password;
    }
}