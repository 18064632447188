import { Injectable } from '@angular/core';
import { SignInData } from './../models/singInData';
import { SalesPerson } from '../models/salesPerson';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
// import { sign } from 'crypto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //   private readonly mockUser: SignInData = new SignInData('user', 'test');
  isAuthenticated = false;
  encryptData!: string;
  userDetails!: SalesPerson;
  loginOutput = false;
  authToken!: string;
  public response:any;
  public checkAuth:any;
  public headerComponent: any;

  constructor(private http: HttpClient, private router: Router) { }

  async authenticate(signInData: SignInData){

    // this.checkCredentials(signInData);
    this.checkAuth = await this.authenticateResponse(signInData);
    if (this.loginOutput) {
      this.isAuthenticated = true;
      this.router.navigate(['assigned-leads']);
      return true;
    }
    this.isAuthenticated = false;
    return false;
  }


 async authenticateResponse(signInData: SignInData){
  this.encryptData = CryptoJS.AES.encrypt(signInData.getPassword().trim(), signInData.getMobile().trim()).toString();
  // let url = "http://localhost:8082/api/login-user";
  let url = environment.apiUrl + "api/login-user";
  // console.log("What is the url",url);
  this.response = await this.http.post(url,
    {
      phone: signInData.getMobile(),
      password: this.encryptData,
      _method: "POST"
    }).toPromise().then(async (data: any) => {
      console.log("response from backend", data);
      if(data.status == "Success"){
        if(data.data.userId){
            let salesDetails:any = {};
            // console.log("what is auth data?",data.data);
            salesDetails.mobile = data.data.mobileNo;
            salesDetails.name = data.data.name;
            salesDetails.userId = data.data.userId;
            this.authToken = data.data.auth;
            localStorage.setItem("salesPerson",JSON.stringify(salesDetails));
            localStorage.setItem("authToken",this.authToken);
            this.loginOutput = true;
            // return;
        } else {
            this.loginOutput = false;
            // return;
        }
      } else {
        this.loginOutput = false;
      }
  });
 }

 resetPassword(resetPasswordData: any) {
  resetPasswordData.oldPassword = CryptoJS.AES.encrypt(resetPasswordData.oldPassword.trim(), resetPasswordData.mobileNo.trim()).toString();
  resetPasswordData.newPassword = CryptoJS.AES.encrypt(resetPasswordData.newPassword.trim(), resetPasswordData.mobileNo.trim()).toString();

   console.log('In resetPassword: ',resetPasswordData);
  let url = environment.apiUrl + "api/reset-password";
  return this.http.post(url, resetPasswordData);
 }

  logout() {
    this.isAuthenticated = false;
    this.router.navigate(['/']);
    if (this.headerComponent) {
      this.headerComponent.emitLoggedInStatus();
    }
    // Remove JWT
    let authToken = localStorage.getItem("authToken");
    if (authToken) {
      localStorage.removeItem(authToken);
      this.authToken = null;
    }
  }

  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  // if localStorage has login information Authentication is set to true. 
  // This is checked when any page is refreshed
  authenticateFromAppComponent(){
    this.isAuthenticated = true;
  }


}