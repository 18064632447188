<!-- <app-login (loginStatus) = "checkLoginStatus($event)" *ngIf = "!loggedIn"></app-login>
    <app-header (loggedInStatus) = "checkLoginStatus($event)" *ngIf = "loggedIn"></app-header> -->

<!-- router-outlet IS INSIDE app-sidebar COMPONENT -->
<!-- <app-sidebar *ngIf = "loggedIn"></app-sidebar> -->


<section class="temp-class">
    <ng-template [ngIf]="!loggedIn&&router.url!=='/reset-password'">
        <app-login (loginStatus)="checkLoginStatus($event)"></app-login>
    </ng-template>
    <ng-template  [ngIf]="router.url==='/reset-password'">
        <reset-password></reset-password>
    </ng-template>

    <ng-template #showContent [ngIf]="loggedIn&&router.url!=='/reset-password'">
        <app-header (loggedInStatus)="checkLoginStatus($event)"></app-header>
    
        <!-- router-outlet IS INSIDE app-sidebar COMPONENT -->
        <app-sidebar></app-sidebar>
    </ng-template>
</section>