import { Component, OnInit } from '@angular/core';
import { SharedService } from './../shared.service';
import { HostListener } from "@angular/core";
import { AuthenticationService } from './../../services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  sideNav: any = true;
  mode:any;
  // title!: any;

  // Declare width variables
  // scrHeight: any;
  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
        // this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;

        if(this.scrWidth <= 1020) {
          this.sideNav = false;
          this.mode = "over";
        } else {
          this.sideNav = true;
          this.mode="side";
        }
        // console.log(this.scrWidth);
        return this.scrWidth;
  }



  constructor(private shared: SharedService,public authenticationService: AuthenticationService) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    // toggle sideBar when Menu Bar clicked in Header
    this.shared.toggleSideBarStatus$
      .subscribe(
        message => {
          this.sideNav = !this.sideNav;
        }
      )

      // Use the title to disable redudant scrolls/scrollbars in specific screens (Ex: Acquisitions)
      // this.shared.receiveAppTitle().subscribe((title) => {
      //   console.log(title);
      //   this.title = title;
      // });
  }

  // Method to always render the page from top
  onActivate(event): void {
    const element = document.querySelector('.scrollId');
    element.scrollIntoView();
  }

  toggleSideBar(): void {
    this.shared.toggleSideNav("toggleSideBar");
  }

  toggleSideBarMobile(): void {
      this.shared.toggleSideNav("toggleSideBar");
  }




}
