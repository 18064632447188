<div class="scrollId">
    <form action="" (ngSubmit)="onSubmit(editAppointmentForm)" #editAppointmentForm="ngForm">
        <div class="form1-align df">
            <!-- <h3>Add User</h3> -->
            <div class="form-group flex-col">
                <label for="appointmentDate" for="">Date <span style="color:red">*</span></label>
                    <!-- <mat-form-field appearance="fill" style="display: none;"> -->
                    <button class="datepicker-btn" type="button" matSuffix (click)="picker.open()">
                        <input matInput [matDatepicker]="picker" placeholder="Select a Date" style="width: 100%;" name="appointmentDate"
                            [min]="todayDate" [formControl]="appointmentDate" required readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker" id="toggle"></mat-datepicker-toggle>
                        <mat-datepicker #picker color="primary"></mat-datepicker>
                    </button>
                    <!-- </mat-form-field> -->

            </div>
            <div class="form-group flex-col">
                <label for="appointmentTime" for="">Time </label>
                <input id="appointmentTime" name="appointmentTime" ngModel="{{appointmentTime | date:'HH:mm'}}"
                value="{{appointmentTime}}" required  onfocus="(this.type='time')" autofocus type="text" style="width: 100% !important;">
                <!-- #appointmentTimeId="ngModel"
                    [ngClass]="{'red-border-class': submitted && appointmentTimeId.errors?.required}"> -->
            </div>
            <div class="form-group flex-col">
                <label for="appointmentDuration" for="">Duration </label>
                <select id="appointmentDuration" name="appointmentDuration" [(ngModel)]="appointmentDuration" autofocus>
                    <option value="" default selected>Select Duration</option>
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="45 mins">45 mins</option>
                    <option value="1 hr">1 hr</option>
                </select>
            </div>
            <div class="form-group flex-col">
                <label for="appointmentType" for="">Type <span style="color:red">*</span></label>
                <ul class="donate-now">
                    <li style="width: 73px;">
                        <input type="radio" name="appointmentType" id="call" [(ngModel)]="appointmentType"
                            value="Call"><label for="call" class="radio-btn-label">Call</label>
                    </li>
                    <li style="width: 73px;">
                        <input type="radio" name="appointmentType" id="inperson" [(ngModel)]="appointmentType"
                            value="Inperson"><label for="inperson" class="radio-btn-label">Inperson</label>
                    </li>
                </ul>
            </div>
        </div>
        <hr>
        <div class="row btn-align">
            <button type="submit" class="save-btn">Save</button>
            <button type="button" class="cancel-btn" (click)="onCancel()">Cancel</button>
        </div>

    </form>
</div>