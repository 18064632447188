import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  

  // headerTitle!:string;

  private _toggleSideBar = new Subject<string>();

  private appTitle = new BehaviorSubject<string>("");
  toggleSideBarStatus$ = this._toggleSideBar.asObservable();

  constructor(private authService:AuthenticationService) {
   }


  toggleSideNav(data:any){
    this._toggleSideBar.next(data);
  }

  sendAppTitle(message:string){
    // console.log("Is the message coming",message);
    let authStatus = this.authService.isAuthenticated;
    let loginStatus = localStorage.getItem("salesPerson");
    // console.log("What is authStatus",authStatus);
    if(authStatus || loginStatus.length > 0){
      this.appTitle.next(message);
     
    } else {
      this.appTitle.next("");
    }
  }

  receiveAppTitle(){
    return this.appTitle.asObservable();
  }

  // subscribeToAppTitle(){
  //   this.receiveAppTitle().subscribe((data) => {
  //     this.headerTitle = data;
  //     console.log("What is headerTitle",this.headerTitle);
  //   })
  // }


  
  
}
