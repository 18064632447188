import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-direction-navigate',
  templateUrl: './direction-navigate.component.html',
  styleUrls: ['./direction-navigate.component.scss']
})
export class DirectionNavigateComponent implements OnInit {

  // taken from github
  mapLoaded: boolean;
  map!: google.maps.Map | null;
  center!: google.maps.LatLngLiteral;

  source!: google.maps.LatLngLiteral;
  destination!: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 8
  }

  ds!: google.maps.DirectionsService;
  dr!: google.maps.DirectionsRenderer;

  geocoder: google.maps.Geocoder;
  sourceAddress: string;
  destinationAddress: string;


  //navigation in google maps
  // geocoder: google.maps.Geocoder;
  // latitude!: any;
  // longitude!: any;
  userLatitude!:any;
  userLongitude !:any;
  zoom = 8;

  // start_end_mark:any = [];

  //  latlng = [
  //   [
  //     17.490299000000004,
  //     78.32333349999999
  //   ],
  //   [
  //     17.4486,
  //     78.3908
  //   ],
  //   [
  //     17.4078777,
  //     78.4992661
  //   ]
  // ];


  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,private router:Router,private route:ActivatedRoute,private shared:SharedService) {
    // this.geocoder = new google.maps.Geocoder();
    this.userLatitude = this.route.snapshot.paramMap.get('userLatitude');
    this.userLongitude = this.route.snapshot.paramMap.get('userLongitude');
    this.geocoder = new google.maps.Geocoder();
    // this.start_end_mark.push(this.latlng[0]);
    // this.start_end_mark.push(this.latlng[this.latlng.length - 1]);

    // this.userLatitude = +this.userLatitude;
    // this.userLatitude = +this.userLongitude
   }

  ngOnInit(): void {
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });

    navigator.geolocation.getCurrentPosition(position => {

      this.source = {
        lat:position.coords.latitude,
        lng:position.coords.longitude
      };
      this.getAddress(this.source.lat,this.source.lng, "source");

      // this.source = {
      //   lat:17.490299000000004,
      //   lng:78.32333349999999
      // };
      // this.destination = {
      //   lat:17.4078777,
      //   lng:78.4992661
      // }
      console.log("userLat",typeof +this.userLatitude,this.userLongitude);
      this.destination = {
        lat: +this.userLatitude,
        lng: +this.userLongitude
      }
      this.getAddress(this.destination.lat,this.destination.lng,"dest");

      // initialize the map container
      this.map = new google.maps.Map(document.getElementById('map-canvas'), {
        ...this.options,
        center: this.source
      });

      // this.map.addListener('tilesloaded', () => {
      //   this.mapLoaded = true;
      // });

      // adding a marker
      var markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          // url:'./assets/images/destination_custom_pin.svg',
          url: './assets/images/sales.svg',
          anchor: new google.maps.Point(35,10),
          scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      var destinationMarker = new google.maps.Marker({
        position: this.destination,
        icon: {
          // url: './assets/iomages/destination_custom_pin.svg',
          url: './assets/images/consumer.svg',
          anchor: new google.maps.Point(35,10),
          scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      this.setRoutePolyline();
    });
  }

    // Get Current Location Coordinates
    // private setCurrentLocation() {
    //   if ('geolocation' in navigator) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       this.latitude = position.coords.latitude;
    //       this.longitude = position.coords.longitude;
    //       this.zoom = 8;
    //       this.source = {
    //         lat:this.latitude,
    //         lng:this.longitude
    //       };
    //       console.log("Source",this.source);
    //       // this.getLocationCheck();
    //     });
    //   }
    // }

    showMap() {
      const link = "http://maps.google.com/maps?saddr="+this.source.lat+","+this.source.lng+" &daddr="+this.destination.lat+","+this.destination.lng;
      window.open(link,'_blank');
   }

   getAddress(latitude: any, longitude: any, variable: any) {
      this.geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            if (variable === "source") {
              this.sourceAddress = results[0].formatted_address;
            } else {
              this.destinationAddress = results[0].formatted_address;
            }
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      });
  }
    setRoutePolyline() {
      let request = {
        origin: this.source,
        destination: this.destination,
        travelMode: google.maps.TravelMode.DRIVING
      };
  
      this.ds.route(request, (response, status) => {
        this.dr.setOptions({
          suppressPolylines: false,
          map: this.map
        });
  
        if (status == google.maps.DirectionsStatus.OK) {
          this.dr.setDirections(response);
          this.mapLoaded = true;
        }
      })
    }

    openGoogleMaps(){
      // window.open("https://www.google.com/maps/dir/?api=1&origin={}");
      window.open(`https://www.google.com/maps/dir/?api=1&origin=${this.source.lat},${this.source.lng}&destination=${this.destination.lat},${this.destination.lng}&travelmode=driving`);
    }

}
